import React from 'react';
import "./Termhero.css";

function TermContainer() {
  return (
    <div className='term-cont-outer'>
      <div className='term-inner-cont'>
        <p>These terms and conditions of Use (hereinafter referred to as “Terms of Usage”) describe and govern the User’s use of the content and services offered by RisoApp India Pvt. Ltd. through <strong className='linkstrong'><a href='https://risoapp.com'>www.risoapp.com</a></strong> (hereinafter referred to as “We,” “Riso,” “us,” “our,” “Riso application,” “Website”).</p>
        <h2>UPDATION</h2>
        <p>The Website may update/amend/modify these Terms of Usage from time to time. The User is responsible to check the Terms of Usage periodically to remain in compliance with these terms.
        </p>
        <h2>USER CONSENT</h2>
        <p>
          By accessing the Website and using it, you (“Member”, “You”, “Your”) indicate that you understand the terms and unconditionally & expressly consent to the Terms of Usage of this Website. If you do not agree with the Terms of Usage, please do not click on the “I AGREE” button. The User is advised to read the Terms of Usage carefully before using or registering on the Website or accessing any material, information or services through the Website. Your use and continued usage of the Website (irrespective of the amendments made from time to time) shall signify your acceptance of the terms of usage and your agreement to be legally bound by the same.
        </p>
        <h2>GENERAL DESCRIPTION</h2>
        <p>The Website is an internet-based portal existing on the World Wide Web, application, and other electronic mediums, providing educational content, reports, data, telephone, video, and email consultations (hereinafter referred to as “Content”). The Website offers “Free Services” and “Paid Services” (collectively referred to as “Services”). Free Services are easily accessible without requiring membership; however, to access personalized educational services, receive additional Content, and gain access to Paid Services, you are required to register as a member on the portal. By registering for Paid Services, a Member agrees to:
        </p>
        <ul>
          <li><p>Provide current, complete, and accurate information about themselves as prompted by the Website.
          </p></li>
          <li>
            <p>
              Maintain and update the above information as required, ensuring the accuracy and completeness of the information provided.
            </p>
          </li>
        </ul>
        <h2>REGISTRATION AND ELIGIBILITY</h2>
        <ul>
          <li>
            <p>
              The User of the Website must be a person who can form legally binding contracts under Indian Contract Act, 1872. A minor under the age of eighteen (18) in most jurisdiction, are not permitted to avail the services provided on the Website without a legal guardian in accordance with the applicable laws. The Website would not be held responsible for any misuse that may occur by virtue of any person including a minor using the services provided through the Website.
            </p>
          </li>
          <li>
            <p>
              For the User to avail the services, the User will be directed to Register as a Member on the Website whereby You (User) agree to provide update, current and accurate information while filling up the sign-in form. All information that you fill and provide to the Website and all updates thereto are referred to in these Terms of Usage as “Registration Data“.
            </p>
          </li>
          <li>
            <p>
              An account could be created by you through the Website ID (Your Phone Number) and password (OTP) or other log - in ID and password which can include a facebook, gmail or any other valid email ID. The User while creating an account hereby represents and warrants that all the information provided by the User is current, accurate and complete and that the User will maintain the accuracy and keep the information updated from time to time. Use of another User’s account information for availing the services is expressly prohibited. If in case it is found that the information so supplied on the Website is inaccurate, incomplete, untrue and not current, the Website has the right to suspend or terminate the User’s account and restrict/refuse the use of the Website by such User in future.
            </p>
          </li>
          <li>
            <p>
              The right to use this Website is personal to the User and is not transferable to any other person or entity. The User would be responsible for protecting the confidentiality of User’s passwords and other information required for the purposes of registration. The User would be fully responsible for all the activities that occur under the User’s account with the Website. The Website cannot and will not be liable for any loss or damage arising from the User’s failure to maintain secrecy and confidentiality. The User shall notify the Website immediately if they become aware of any unauthorized use of their Account(s) or breach of any security. The User must log out from its account at the end of the session.
            </p>
          </li>
          <li>
            <p>
              The User while availing any service shall be informed whether the service so rendered is personal to the Website or is available from a Third party. The Website shall have no control or monitoring on the information disseminated to any third party via the Website.
            </p>
          </li>
          <li>
            <p>
              The User agrees, understands and confirms that his/ her personal data including without limitation to details relating to debit card/ credit card transmitted over the Internet may be susceptible to misuse, hacking, theft and/ or fraud and that the Website or the Payment Service Provider(s) have no control over such matters.
            </p>
          </li>
          <li>
            <p>
              The Website does not permit the use of the Services by any User under the following conditions: -
            </p>
          </li>
          <ul>
            <li>
              <p>
                If the User is a resident of any jurisdiction that may prohibit the use of the Services rendered by the Website.

              </p>
            </li>
            <li>
              <p>
                If the User is a resident of any State/Country that prohibits by way of law, regulation, treaty or administrative act for entering into trade relations or/and

              </p>
            </li>
            <li>
              <p>
                Due to any religious practices.
              </p>
            </li>
            <li>
              <p>
                If the User has created multiple accounts using various mobile numbers. The User may not have more than one active account with the Website.
              </p>
            </li>
          </ul>
        </ul>
        <h2>FEATURE “CALL WITH COUNSELOR”</h2>
        <p>The Website is providing certain services which are available through the medium of telecommunication with the counselors listed and enrolled with the Website. By agreeing to the present Terms of Usage, you are also giving your unconditional consent to the Website to arrange a call with you on your mobile number even though your number is on DND service provided by your mobile service provider.
        </p>
        <h2>WEBSITE CONTENT</h2>
        <p>
          The Website and any individual websites which may be available through external hyperlinks are private property.
        </p>
        <p>
          All interactions on this Website, including guidance and advice received directly from the licensed providers, must comply with these Terms of Usage.
        </p>
        <p>
          The User shall not post or transmit through this Website any material that violates or infringes in any way upon the rights of others, or any material that is unlawful, abusive, defamatory, invasive of privacy, vulgar, obscene, profane, or otherwise objectionable, which encourages conduct that would constitute a criminal offense, give rise to civil liability, or otherwise violate any law.

        </p>
        <p>
          The Website reserves the right to suspend or terminate access by such User or to terminate the User’s registration, and such User shall not gain access to the Website.

        </p>
        <p>
          The Website reserves the right to change or discontinue any aspect or feature of the Website, including but not limited to content, graphics, deals, offers, settings, etc.

        </p>
        <p>
          Any information other than the guidance and advice received directly from the Third-Party Service Provider, as well as educational content, graphics, research sources, and other incidental information on the Site, should not be considered as medical advice.

        </p>
        <p>
          The Website does not guarantee the medical advice provided by third-party service providers, including registered counselors associated with the site. The User should always consult an appropriately qualified healthcare professional for diagnosis and treatment, including information regarding which medications or treatments may be appropriate for the User. None of the Content represents or warrants that any particular medication or treatment is safe, appropriate, or effective for you. Riso does not endorse any specific tests, medications, products, or procedures.
        </p>
        <p>
          The Website does not guarantee against any untoward incident that may happen to the User after seeking the Service. The Website or the Service Provider providing the advice is not liable and does not guarantee any results as expected by the User, and accessing the Website in such scenarios is purely at the User's risk.
        </p>
        <p>
          By using the Site, Application, or Services, the User hereby agrees that any legal remedy or liability sought for actions or omissions of other Members, including the service providers registered with the Website or other third parties linked to the Website, shall be limited to a claim against such particular party who may have caused any harm. The User agrees not to attempt to impose liability on or seek any legal remedy from the Website concerning such actions or omissions.

        </p>

        <h2>USER ACCOUNT ACCESS</h2>
        <p>
          The Website shall have access to the account and the information created by the User for ensuring and maintaining the high-quality services provided by the Website and for addressing the need of the customer in the most effective manner. User hereby consents for the unconditional access of the account by the Website, its employees, agents and other appointed person in such regard. For the purpose of addressing the complaints (if any received) and any suspected abuse reported, the Website shall investigate on case-to-case basis from the records available. The User is directed to read the terms provided in the Privacy Policy as regards such records.

        </p>
        <h2>PRIVACY POLICY</h2>
        <p>The User hereby consents, expresses and agrees that the User has read and fully understand the Privacy Policy of the Website. The User further consents that the terms and contents of such Privacy policy is acceptable to the User inclusive of any update/alteration/change made and duly displayed on the Website.
        </p>
        <h2>BREACH AND TERMINATION</h2>
        <ul>
          <li>
            <p>
              The Website may, in whole or in part, without informing the User, modify, discontinue, change or alter the services ordered or the Account of the User registered with the Website. The Website may or may not issue notice or provide any reason for such action taken by the Website.
            </p>
          </li>
          <li>
            <p>Violation of any conditions mentioned in this Terms of Usage shall lead to immediate cancellation of the Registration of the User, if registered with the Website. The Website reserves right to terminate and initiate action immediately, if:
            </p>
          </li>
          <ul>
            <li>
              <p>The Website is not able to verify and authenticate the Registration data or any other relevant information provided by the User.
              </p>
            </li>
            <li>
              <p>
                The Website believes that the actions of the User may cause legal liability for the Website, other Users or any service provider linked with the Website.

              </p>
            </li>
            <li>
              <p>
                The Website believes that the User has provided the Website with false and misleading Registration Data or there is interference with the other Users or the administration of the services, or have violated the privacy policy as listed by the Website.
              </p>
            </li>
          </ul>
          <li>
            <p>For the Service Provider inclusive of the Astrologer, You understand and agree that your relationship with the Website is limited to being a member and You act exclusively on your own behalf and for your own benefit. The Website may terminate and de-activate the Profile of such service provider for any violation of the present terms of usage and the Service Terms and Conditions agreed upon between the parties while registration of the data by such Service Provider.
            </p>
          </li>
        </ul>
        <h2>DELIVERY, CANCELLATION AND REFUND</h2>
        <p>
          No refund shall be processed on the order of any reports under any circumstances if the order has reached the “processing” stage (Assigned to a Counselor). The risk and liability of placing an order in haste and carelessly lies entirely with the User, and Riso is not responsible for any refund once the processing stage has started.

        </p>
        <p>
          No refund shall be processed once the Order has been placed and executed. However, if the User intends to cancel a successfully placed order before execution, the User must contact the customer care team within 1 (one) hour of making the payment. Thereafter, it is entirely at the discretion of Riso whether to issue a refund.

        </p>
        <p>
          Any technical delay or glitch reported on the Website during the processing of the request, which includes generating reports by the service provider (i.e., Counselor), shall not be eligible for a refund. The User agrees that the timelines are approximate, and all essential steps will be taken to adhere to the timelines as displayed.

        </p>
        <p>
          No refund shall be processed for reasons that incorrect information or data has been provided by the User. The User agrees to be careful while providing any information to the Website and must re-check the information filled before clicking on “Submit.” The User can request a change in the incorrect information or data entered, provided the request for such change has been made with customer care within 1 (one) hour of the execution of the service rendered by the service provider.

        </p>
        <p>
          No refund shall be processed for the return of any damaged product. The User undertakes and agrees that by ordering any product as displayed on the Website, the Registered User shall be fully responsible for any damage caused to the product post-delivery. For orders made via the “Cash on Delivery” method of payment, the User shall be charged for the cost of the product as displayed by the Website, along with any applicable shipping/custom/courier charges if the product is returned.

        </p>
        <p>
          Refunds on a pro-rata basis may be considered for any delay in the activation of the subscription services. Any damage that may be caused to the product while in transit shall be dealt with by Riso and its agencies.

        </p>
        <p>
          The User agrees that the display pictures for the products listed for purchase are for reference purposes only, and Riso will try to deliver the product ordered in an as-is condition as displayed on the Website. The User is advised to exercise discretion in such cases, and no refund shall be issued on such grounds.

        </p>
        <p>
          The services offered and the products sold are strictly not meant to replace any philosophical, emotional, or medical treatment. Riso holds no responsibility or liability regarding the reality or reliability of the effects of the services or products on the User. The User's decision to order any product or service is solely at their discretion, and Riso does not assume responsibility for the products sold. The User is advised to exercise discretion in such cases, and no refund shall be issued on such grounds.

        </p>
        <p>
          No refund shall be processed for providing a wrong contact number for the purpose of availing the “Call with Counselor” feature. The User, once opted for this feature, is advised to keep the contact number within full coverage and must answer the call when received. No refund shall be processed for any call that gets connected.

        </p>
        <p>
          Refunds, if any, shall be processed after deducting transaction charges levied by the Bank and/or the Payment Gateway, to and from the cost of shipping and/or courier charges (with regard to the purchase of a product listed on the Website), customs duty (if levied), and/or any other charges that may have been incurred by Riso during processing and/or delivering the service, as applicable.

        </p>
        <p>
          In case Riso or the payment gateway’s webpage linked to the Website is experiencing any server-related issues such as ‘slow down,’ ‘failure,’ or ‘session timeout,’ the User shall, before initiating a second payment, check whether their Bank Account has been debited or not and accordingly resort to one of the following options:

        </p>
        <ul>
          <li>
            <p>
              If the Bank Account appears to be debited, ensure that you do not make the payment twice and immediately thereafter contact Riso via customer care to confirm payment.

            </p>
          </li>
          <li>
            <p>
              If the Bank Account is not debited, the User may initiate a fresh transaction to make payment.

            </p>
          </li>
        </ul>

        <p>
          However, refunds for multiple payments, if any, even after the above precautions against the same order, shall be refunded in full without deduction of the transaction charges as mentioned above. Riso shall only retain the cost of a single order as intended to be placed by the User.

        </p>
        <p>
          If there are orders that Riso is unable to accept and must cancel, Riso reserves the right, at its sole discretion, to refuse or cancel any order for any reason whatsoever. Some situations may result in an order being canceled and include, without limitation, non-availability of the service, inaccuracies, errors in pricing information, or other identified problems. If the User’s order is canceled after charges have been paid against the said service, the said amount paid for booking shall be refunded.

        </p>

        <h2>USER OBLIGATION</h2>
        <p>
          The User (including counselors and registered members) is obligated not to violate the privacy policy, terms and conditions, and any other terms defined on the Riso Website. The User represents that they are an individual and not a corporation or other legal business entity. The rights to use Riso’s services are personal to the User. The User shall, while using the Website and engaging in any form of communication on any of the forums, including the products listed on the Website, not violate the following terms and conditions:

        </p>
        <h5>1. Content Restrictions</h5>
        <p>
          The User shall not post, publish, or transmit any messages that are false, misleading, defamatory, harmful, threatening, abusive, harassing, invasive of another's privacy, offensive, or promote racism, hatred, or harm against any individual or group based on religion, caste, or any other characteristic. The User shall also not infringe upon another's rights, including any intellectual property rights or copyright or trademark, or encourage any conduct that would violate applicable law or regulation or give rise to civil liability.

        </p>
        <h5>2. Rights to Content</h5>
        <p>
          The User shall not upload or post or otherwise make available any content that they do not have the right to make available under any law or under contractual or fiduciary relationships. The User shall not upload or post content that infringes any patent, trademark, trade secret, copyright, or other proprietary rights of any party. However, the User may post excerpts of copyrighted material as long as they adhere to Fair Use guidelines.

        </p>
        <h5>3. Data Collection</h5>
        <p>
          The User shall not collect screen names and email addresses of registered members for purposes of advertisement, solicitation, or spam.

        </p>
        <h5>4. Unsolicited Communications</h5>
        <p>
          The User shall not send unsolicited emails, junk mail, spam, chain letters, or promotions or advertisements for products or services.

        </p>
        <h5>5. Malicious Software</h5>
        <p>The User shall not upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website or another’s computer.
        </p>
        <h5>6. Interference</h5>
        <p>
          The User shall not engage in any activity that interferes with or disrupts access to the Website.

        </p>
        <h5>7. Unauthorized Access</h5>
        <p>The User shall not attempt to gain unauthorized access to any portion or feature of the Website, any systems or networks connected to the Website, or any of the services offered on or through the Website by hacking, password mining, or any other illegitimate means.
        </p>
        <h5>8. Compliance with Laws</h5>
        <p>
          The User shall not violate any applicable laws or regulations in force within or outside India. The use of the Website is subject to using the services for personal use only.

        </p>
        <h5>9. Commercial Use</h5>
        <p>
          The User shall not resell or make any commercial use of the Services without the express written consent from Riso.

        </p>
        <h5>10. Terms of Usage</h5>
        <p>The User shall not violate these Terms of Usage, including any applicable additional terms of the Website.
        </p>
        <h5>11. Prohibited Actions</h5>
        <p>
          The User shall not reverse engineer, modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Website.

        </p>
        <h5>12. User Agreement</h5>
        <p>
          By becoming a registered member of the Website, the User agrees to the following, which is not an exhaustive list and may include services incidental to the following:
        </p>
        <ul>
          <li>
            <p>
              The User agrees to receive certain specific emails and SMS along with calls from Riso.

            </p>
          </li>
          <li>
            <p>
              The User agrees not to transmit any unlawful, harassing, libelous, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable material of any kind via the Website.
            </p>
          </li>
          <li>
            <p>
              The User shall not transmit any material that encourages conduct that could constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable local, state, national, or international law or regulation. Attempts to gain unauthorized access to other computer systems are prohibited.

            </p>
          </li>
          <li>
            <p>
              The User shall not interfere with any other members' use or enjoyment of the Website or Services.
            </p>
          </li>
        </ul>
        <h5>13. Reporting Misuse</h5>
        <p>
          The User is obligated to report any misuse or abuse of the Site. If the User notices any abuse or misuse of the Site or anything in violation of this Agreement, they shall immediately report such violation to Riso by writing to Customer Care. Upon receipt of such a complaint, Riso may investigate the matter and, if necessary, terminate the membership of the member responsible for such violation, abuse, or misuse without any refund of the subscription fee.

        </p>
        <h5>14. False Complaints</h5>
        <p>Any false complaint made by a member shall make that member liable for termination of their membership without any refund of the subscription fee.
        </p>
        <h5>15. Customer Conduct</h5>
        <p>
          Riso reserves the right to withdraw its services from any customer found to be unreasonable or abusive during their conversation with the service provider, including counselors, regardless of the reason.

        </p>
        <h5>16. Response to Violations</h5>
        <p>
          While Riso will take all necessary steps to resolve any situation that violates these obligations, if a situation arises that is uncontrollable, Riso reserves the right to issue a written warning. Repeated violations by the User shall lead to a total ban on transacting on the platform. If any balance is present in the User's wallet, it shall be refunded, subject to any applicable charges for such violations.

        </p>

        <h2>BANK ACCOUNT INFORMATION</h2>
        <p>The User is under an obligation to provide his banking information as and when required. For that purpose, the obligation of the User are:-</p>
        <ul>
          <li>
            <p>
              The User agrees that the debit/credit card details provided by him/ her for use of the aforesaid Service(s) must be correct and accurate and that the User shall not use a debit/ credit card, that is not lawfully owned by him/ her or the use of which is not authorized by the lawful owner thereof. The User further agrees and undertakes to provide correct and valid debit/credit card details.

            </p>
          </li>
          <li>
            <p>
              The User may pay the fees required, to the Website by using a debit/credit card or through online banking account. The User warrants, agrees and confirms that when he/ she initiates a payment transaction and/or issues an online payment instruction and provides his/ her card / bank details:
            </p>
          </li>
          <ul>
            <li>
              <p>
                The User is fully and lawfully entitled to use such credit / debit card, bank account for such transactions;

              </p>
            </li>
            <li>
              <p>
                The User is responsible to ensure that the card/ bank account details provided by him/ her are accurate;
              </p>
            </li>
          </ul>
          <li>
            <p>
              The User is responsible to ensure sufficient credit is available on the nominated card/ bank account at the time of making the payment to permit the payment of the dues payable or the bill(s) selected by the User inclusive of the applicable Fee.

            </p>
          </li>
        </ul>
        <p>
          The User further agrees that if any part of these Terms of Usage are determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth herein, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these Terms of Usage shall continue in effect.
        </p>
        <h2>DISCLAIMER / LIMITATION OF LIABILITY / WARRANTY</h2>
        <p>
          The User expressly understands and agrees that, to the maximum extent permitted by applicable law, Riso does not provide warranties for its services. Counseling provided through the Riso platform is based on the cumulative or individual knowledge, experience, and interpretations of counselors and may vary from one counselor to another.

        </p>
        <p>
          Riso offers services through a diverse panel of verified counselors. These counselors may, from time to time, make recommendations for using various remedies, such as tools or products related to education and career guidance. Such recommendations are made in good faith by the counselors, and Riso and its subsidiaries, affiliates, officers, employees, agents, partners, and licensors make no warranty that:

        </p>
        <ul>
          <li>
            <p>
              The service will meet your requirements
            </p>
          </li>
          <li>
            <p>
              The service will be uninterrupted, timely, secure, or error-free.
            </p>
          </li>
          <li>
            <p>
              The service will be uninterrupted, timely, secure, or error-free.
            </p>
          </li>
          <li>
            <p>
              The results obtained from the use of the service will be accurate or reliable.
            </p>
          </li>
          <li>
            <p>
              The quality of any products, services, information, or other material purchased or obtained by you through the service will meet your expectations.
            </p>
          </li>
          <li>
            <p>Any errors in the software will be corrected.</p>
          </li>
        </ul>
        <p>
          Users are required to fully disclose their emotional, mental, and physical state when seeking advice from Riso counselors so that informed judgments can be made.

        </p>
        <p>
          The Website, services, and other materials are provided on an "as is" basis without warranty of any kind, express, implied, statutory, or otherwise, including the implied warranties of title, non-infringement, merchantability, or fitness for a particular purpose. Without limiting the foregoing, Riso makes no warranty that:

        </p>
        <ul>
          <li>
            <p>
              The Website or services will meet your requirements or that your use of the Website or services will be uninterrupted, timely, secure, or error-free.

            </p>
          </li>
          <li>
            <p>
              The results obtained from the use of the Website, services, or materials will be effective, accurate, or reliable.

            </p>
          </li>
          <li>
            <p>
              The quality of the Website, services, or other materials will meet your expectations.

            </p>
          </li>
          <li>
            <p>
              Any errors or defects in the Website, services, or other materials will be corrected.

            </p>
          </li>
        </ul>
        <p>
          No advice or information, whether oral or written, obtained by the User from Riso or through or from the use of the services shall create any warranty not expressly stated in the terms of use.

        </p>
        <p>
          To the maximum extent permitted by applicable law, Riso will have no liability related to User content arising under intellectual property rights, libel, privacy, publicity, obscenity, or other laws. Riso also disclaims all liability with respect to the misuse, loss, modification, or unavailability of any User content.

        </p>
        <p>
          Riso will not be liable for any loss that the User may incur as a consequence of unauthorized use of their account or account information in connection with the Website or any services or materials, whether with or without the User’s knowledge. Riso has endeavored to ensure that all information on the Website is correct, but it neither warrants nor makes any representations regarding the quality, accuracy, or completeness of any data, information, product, or service. Riso shall not be responsible for the delay or inability to use the Website or related functionalities, the provision of or failure to provide functionalities, or for any information, software, products, functionalities, and related graphics obtained through the Website, or otherwise arising out of the use of the Website, whether based on contract, tort, negligence, strict liability, or otherwise. Furthermore, Riso shall not be held responsible for the unavailability of the Website during periodic maintenance operations or any unplanned suspension of access to the Website that may occur due to technical reasons or for any reason beyond Riso's control.

        </p>
        <p>
          The User understands and agrees that any material or data downloaded or otherwise obtained through the Website is done entirely at their own discretion and risk, and they will be solely responsible for any damage to their computer systems or loss of data that results from the download of such material or data. Riso is not responsible for any typographical error leading to an invalid coupon. Riso accepts no liability for any errors or omissions with respect to any information provided to you, whether on behalf of itself or third parties.

        </p>
        <p>
          The services provided by Riso are for informational purposes only, and Riso, on behalf of itself and its suppliers, disclaims all warranties of any kind, express or implied, including without limitation any warranty of merchantability, fitness for a particular purpose, title, or non-infringement. It makes no warranty or representation regarding the results that may be obtained from the use of content or services, the accuracy or reliability of any content obtained through the services, or any goods or services purchased or obtained through the Website, and makes no warranty that the services will meet your requirements, be uninterrupted, timely, secure, or error-free. No advice or information, whether oral or written, obtained by you from Riso shall create any warranty.

        </p>
        <p>
          The services may consist of, without limitation: educational content, reports, consultations, or products sold through the Riso platform. Riso charges for chat or call services offered on this platform on a per-minute basis and holds no responsibility or liability regarding the accuracy or reliability of any effects on educational or career outcomes related to any products or services represented and sold on the Website. No advice or information, whether oral or written, obtained by you shall create any warranty.

        </p>
        <p>
          The advisors/counselors are also members of the site and not employees of Riso. However, Riso verifies the degrees, qualifications, credentials, and backgrounds of the advisors/counselors but does not refer, endorse, recommend, verify, evaluate, or guarantee any advice, information, or other services provided by the advisors/counselors or by the company, nor does it warrant the validity, accuracy, completeness, safety, legality, quality, or applicability of the content or any advice provided by the advisors/counselors.

        </p>
        <p>
          Riso is not a mental health support platform. If you are considering or contemplating self-harm or feel that you are a danger to yourself or others, you may discontinue use of the services immediately at your discretion and please notify appropriate authorities or emergency medical personnel. If you are in crisis, please reach out to a crisis hotline or mental health professional.

        </p>
        <p>
          Riso shall not be liable for any inaccuracy, error, delay, or omission of (a) any data, information, or message; (b) the transmission or delivery of any such data, information, or message; or (c) any loss or damage arising from or occasioned by any such inaccuracy, error, delay, or omission, non-performance, or interruption in any such data, information, or message. Under no circumstances shall Riso and/or the payment service providers, its employees, directors, and third-party agents involved in processing, delivering, or managing the services, be liable for any direct, indirect, incidental, special, or consequential damages, or any damages whatsoever, including punitive or exemplary damages arising out of or in any way connected with the provision of, or any inadequacy or deficiency in, the provision of the services or resulting from unauthorized access or alteration of transmissions of data or arising from suspension or termination of the services.

        </p>
        <p>
          Notwithstanding anything to the contrary contained herein, Riso's liability to you for any cause whatsoever, and regardless of the form of the action, will at all times be limited to the amount paid, if any, by you to Riso for the service during the term of membership.

        </p>
        <h2>INDEMNIFICATION</h2>
        <p>
          The User shall indemnify, defend and hold harmless the Website and its parent, subsidiaries, affiliates, officers, directors, employees, suppliers, consultants and agents from any and all third party claims, liability, damages and/or costs (including, but not limited to, attorney’s fees) arising from Your use of the Services, Your violation of the Privacy Policy or these Terms of Service, or Your violation of any third party's rights, including without limitation, infringement by You or any other user of Your account of any intellectual property or other right of any person or entity. These Terms of Service will inure to the benefit of Website’s successors, assigns, and licensees.

        </p>
        <h2>PROPRIETARY RIGHTS TO CONTENT</h2>
        <p>
          The User acknowledges that the Content, including but not limited to text, software, music, sound, photographs, video, graphics, or other material contained in sponsor advertisements or distributed via email, as well as commercially produced information presented to Users by Riso, its suppliers, and/or advertisers, is protected by copyrights, trademarks, service marks, patents, and/or other proprietary rights and laws. The User is not permitted to copy, use, reproduce, distribute, perform, display, or create derivative works from the Content unless expressly authorized by Riso, its suppliers, or advertisers.

        </p>
        <p>
          Furthermore, the content such as images, text, designs, etc., on all portals of Riso are sourced from various online platforms, including Google Images. Riso is not liable for any copyrights associated with that content or data.

        </p>
        <h2>NOTICES</h2>
        <p>
          Except as otherwise stated in these Terms of Service, all notices to a party shall be in writing and shall be made either via email or postal mail. Notice shall be deemed given 24 hours after an email is sent, or 3 days after deposit in postal mail, to the User at the address provided by the User in the Registration Data and to Riso at the address set forth below:
        </p>
        <p>
          Alkapuri Cmplx Khodasingi Engineering School, Brahmapur Sadar Ganjam, Odisha, 760010 India.
        </p>
        <h5>GOVERNING LAW AND JURISDICTION</h5>
        <ul>
          <li>
            <p>
              Any dispute, claim or controversy arising out of or relating to this Terms of Usage including the determination of the scope or applicability of this Terms of Usage to arbitrate, or your use of the Application or information to which it gives access, shall be determined by arbitration in India, before a sole arbitrator mutually appointed by Members and Website. Arbitration shall be conducted in accordance with the Arbitration and Conciliation Act, 1996. The seat of such arbitration shall be New Delhi. All proceedings of such arbitration, including, without limitation, any awards, shall be in the English language. The award shall be final and binding on the parties to the dispute.

            </p>
          </li>
          <li>
            <p>
              Notwithstanding the foregoing, either party has the right to seek any interim or preliminary relief from a court of competent jurisdiction in New Delhi in order to protect the rights of such party pending the completion of any arbitration hereunder, and both parties agree to submit to the exclusive jurisdiction of the courts of India and venue in New Delhi for any such proceeding. If either party files an action contrary to this provision, the other party may recover attorneys' fees and costs up to One Lakh Rupees INR.

            </p>
          </li>
          <li>
            <p>
              These Terms of Usage shall be governed by and construed in accordance with the laws of India without giving effect to any choice of law and principles that would require the application of the laws of a different state. If for any reason a court of competent jurisdiction finds any provision or portion of these Terms of Usage or Privacy Policy to be unenforceable or invalid, such provision shall be changed and interpreted so as to best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law, and the remainder of the Terms of Usage or Privacy Policy, as applicable, will continue in full force and effect. Headings are for reference purposes only and in no way define, limit, construe, or describe the scope or extent of such section. Any waiver of any provision of the Terms of Usage shall be effective only if in writing and signed by Astrotalk. These Terms of Usage constitute the entire agreement between the parties with respect to the subject matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.

            </p>
          </li>
          <li>
            <p>
              These Terms of Usage and your use of the Services will be interpreted in accordance with the laws of India excluding its rules on conflicts of laws. The parties agree to submit any dispute arising under these Terms of Usage to the jurisdiction of a court located in New Delhi for any actions for which the parties retain the right to seek injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation or violation of a party's copyrights, trademarks, trade secrets, patents, or other intellectual property rights.

            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default TermContainer