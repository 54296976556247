import React, { useState } from 'react';
import './ask.css';
import { Backdrop, Box, Modal, Fade, Typography } from '@mui/material';
import { useLandingPageQuery } from '../../slice/adminDataApiSlice';
import { generatePublicUrl } from '../../config';
import { useBookSlotMutation } from '../../slice/authApiSlice';
import { toast, ToastContainer } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 500,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

function AllAsk() {
  const [open, setOpen] = useState(false);
  const [selectedExpert, setSelectedExpert] = useState(null); // Store expert details
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phNumber, setPhNumber] = useState('');
  const [slotDate, setSlotDate] = useState('');

  const { data, isLoading } = useLandingPageQuery();
  const [bookSlot, { isLoading: bookLoading }] = useBookSlotMutation();

  const handleOpen = (expert) => {
    setSelectedExpert(expert); // Set the selected expert data
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedExpert(null); // Clear selected expert on close
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,10}$/.test(value)) {
      setPhNumber(value);
    }
  };

  const handleBooking = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!selectedExpert) return;

    if (!name || !email || !phNumber || !slotDate) {
      toast.error('All fields are required');
      return;
    }

    if (!emailRegex.test(email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    const payload = {
      name,
      email,
      phNumber,
      counselorName: selectedExpert.name,
      counselorDegi: selectedExpert.designation,
      slotDate,
    };

    console.log('Booking Payload:', payload);

    try {
      const res = await bookSlot(payload).unwrap(); // Use unwrap to access the response
      console.log('Response:', res);

      if (res) {
        toast.success("successfully booked");
        // Reset state
        setName('');
        setEmail('');
        setPhNumber('');
        setSlotDate('');
        handleClose(); // Close modal on success
      }
    } catch (error) {
      console.error('Booking Error:', error);
      toast.error('Failed to book a session. Please try again.');
    }
  };

  return (
    <div className="experts-container1">
      <section className="expert-Inr1">
        <div className="experts-cards1">
          {data?.[0]?.ourCounciller?.length > 0 ? (
            data[0].ourCounciller.map((expert, index) => (
              <div className="expert-card" key={index}>
                <img
                  className="expert-img"
                  src={generatePublicUrl(expert.image)}
                  alt={expert.name}
                />
                <div className="expert-info">
                  <h3>{expert.name}</h3>
                  <p>{expert.designation} | {expert.experience}</p>
                  <p className="languages">{expert.languages}</p>
                  <div className="rating">⭐ {expert.rating}</div>
                </div>
                <div className="expert-actions">
                  <button className="chat-btn">
                    <p>Chat</p> <h5>{expert.chatPrice}</h5>
                  </button>
                  <button className="voice-btn">
                    <p>Voice Call</p> <h5>{expert.voicePrice}</h5>
                  </button>
                  <hr />
                  <button
                    className="session-btn"
                    onClick={() => handleOpen(expert)}
                  >
                    <p>Book a Session</p> <h5>{expert.sessionPrice}</h5>
                  </button>
                </div>
              </div>
            ))
          ) : (
            <p>No experts available at the moment.</p>
          )}
        </div>
      </section>

      {/* Modal for Booking */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <section className="askModal">
              <h2>Book your slot now</h2>
              <p>
                Book a session with <strong>{selectedExpert?.name}</strong>.
              </p>

              <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{
                  border: '2px solid black',
                  borderRadius: '4px',
                  padding: '8px',
                  width: '100%',
                  fontSize: '16px',
                  marginBottom: '10px',
                  outline: 'none',
                }}
              />

              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  border: '2px solid black',
                  borderRadius: '4px',
                  padding: '8px',
                  width: '100%',
                  fontSize: '16px',
                  marginBottom: '10px',
                  outline: 'none',
                }}
              />

<input
                type="text"
                placeholder="Enter your phone number"
                value={phNumber}
                onChange={handlePhoneChange}
                style={{
                  border: '2px solid black',
                  borderRadius: '4px',
                  padding: '8px',
                  width: '100%',
                  fontSize: '16px',
                  marginBottom: '10px',
                  outline: 'none',
                }}
              />
            <>{phNumber.length > 0 && phNumber.length < 10 && (
  <span style={{ color: 'red', fontSize: '12px', marginTop: "-20px" }}>
    Phone number must be 10 digits.
  </span>
)}</>

              <input
                type="datetime-local"
                value={slotDate}
                onChange={(e) => setSlotDate(e.target.value)}
                style={{
                  border: '2px solid black',
                  borderRadius: '4px',
                  padding: '8px',
                  width: '100%',
                  fontSize: '16px',
                  outline: 'none',
                }}
              />

              <button
                onClick={handleBooking}
                style={{
                  marginTop: '15px',
                  padding: '10px',
                  width: '100%',
                  backgroundColor: 'black',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                }}
              >
                Book now
              </button>
            </section>
          </Box>
        </Fade>
      </Modal>
      <ToastContainer/>
    </div>
  );
}

export default AllAsk;
