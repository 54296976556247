// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progrm-job-container {
  position: relative;
  top: 1330px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 565px) {
  .progrm-job-container {
    position: relative;
    top: 800px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pagesections/Programsec.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;AACF","sourcesContent":[".progrm-job-container {\n  position: relative;\n  top: 1330px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n@media (max-width: 565px) {\n  .progrm-job-container {\n    position: relative;\n    top: 800px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
