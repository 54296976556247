import React, { useState } from "react";
import "./Termhero.css";
import { useDispatch } from "react-redux";

function DeleteContainer() {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleDelete = async () => {
    if (!email.trim()) {
      setError("Please enter your email address.");
      return;
    }

    // Simulating API call for account deletion
    setShowModal(false); // Close confirmation modal
    setShowSuccessModal(true); // Show success message modal
    setError(""); // Reset error state
  };

  return (
    <div className="term-cont-outer">
      <div className="term-inner-cont">
        <h2>Delete My Account</h2>
        <p>Once deleted, your account cannot be recovered.</p>
        <p>
          Deleting your account will delete all personal information, course
          information, purchase information, certificates, and data held on the
          site.
        </p>

        <button onClick={() => setShowModal(true)} className="delete-btn">
          Delete Account
        </button>

        {/* Delete Confirmation Modal */}
        {showModal && (
          <div className="modal-delete">
            <div className="modal-content-delete">
              <div className="delete-header">
                <h2>Delete my account</h2>
              </div>
              <div className="delete-content">
                <p>
                  Are you sure you want to delete your account? This will erase
                  all of your account data from the site. To delete your account,
                  enter your account email below:
                </p>
                <input
                  type="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {error && <p className="error-message">{error}</p>}

                <div className="delete-btn-cont">
                  <button onClick={handleDelete} className="confirm-btn">
                    Yes, Delete
                  </button>
                  <button onClick={() => setShowModal(false)} className="cancel-btn">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Success Modal */}
        {showSuccessModal && (
          <div className="modal-delete">
            <div className="modal-content-delete">
              <div className="delete-header">
                <h2>Account Deletion Requested</h2>
              </div>
              <div className="delete-content">
                <p>
                  Your request to delete your account has been successfully sent to our
                  support team. Your account will be permanently deleted within 30
                  days. Please do not log in during this period.
                </p>
                <div className="delete-btn-cont">
                <button onClick={() => setShowSuccessModal(false)} className="confirm-btn">
                  Okay
                </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeleteContainer;
