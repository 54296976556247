import React from "react";
import "./Visioncard.css";

function Visioncrad() {
  return (
    <div className="vison-card">
      <div className="card-title">Where we're heading</div>
      <div className="card-desc">
        Redefine counseling by integrating AI with human expertise to provide
        personalized guidance at every stage of an individual’s educational and
        career journey. 
      </div>
    </div>
  );
}

export default Visioncrad;
