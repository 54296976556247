import React, { useState } from 'react';
import './ask.css';
import SImg from "../../assets/images/expertpanel.png";
import { Link } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FaArrowRight } from 'react-icons/fa';
import { useLandingPageQuery } from '../../slice/adminDataApiSlice';
import { generatePublicUrl } from '../../config';
import moment from 'moment/moment';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: 650,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
  };



function BlogsAll() {

    const [open, setOpen] = React.useState(false);
    const [blogs, setBlogs] = useState();
    const handleOpen = (blog) => {
      setOpen(true)
      setBlogs(blog)
      console.log(blog);
      
    };
    const handleClose = () => setOpen(false);
    const { data, isLoading } = useLandingPageQuery();
  return (
    <div className="experts-container1">
    <section className='expert-Inr1'>
    <div className="recommended_blog_card_container">
                
    <div className="recommended_blog_card_container">
                {isLoading ? (
                  <div>loader</div>
                ) : (
                   data[0].blog?.map((blog) => (
                    <>
                  <div className="blog-data">
                    <div className="blog-img-data">
                      <img src={generatePublicUrl(blog?.image)}></img>
                    </div>
                    <div className="blog-desc-data">
                      <h5>{blog.title}</h5>
                      <p>{blog.description.slice(0, 63)}...</p>
                      <p>{moment(blog.createdAt).format("DD/MM/YYYY")}</p>
                    </div>
                    <div className="blog-arrow-btn">
                      <p>
                        <FaArrowRight onClick={() => handleOpen(blog)} />
                      </p>
                    </div>
                  </div>
                    </>
                   ))
                )}
              </div>
           
               
                
             
                

              
                  
            </div>
      </section>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className='blogOuter-modal'>
            <div className='blogImgModal'>
              <img src={generatePublicUrl(blogs?.image)}/>
              </div>
             <section className='blogModal'>
            
             
                <h2>{blogs?.title}</h2>
                 <p>{blogs?.description}</p>
             </section>
             </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default BlogsAll;
