import React from "react";
import "./Counter.css";

function Counter() {
  return (
    <div className="counter-container">
      <div className="counter-content">
        <section>
          <p>200+</p>
          <p>
            <small>Industries Experts</small>
          </p>
        </section>
        <section>
          <p>100+</p>
          <p>
            <small>University Partners</small>
          </p>
        </section>
        <section>
          <p>999+</p>
          <p>
            <small>Company Partners</small>
          </p>
        </section>
        <section>
          <p>2.5M</p>
          <p>
            <small>Active Jobs</small>
          </p>
        </section>
      </div>
    </div>
  );
}

export default Counter;
