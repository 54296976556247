import React from 'react';
import "./Termhero.css";

function TrustContainer() {
  return (
    <div className='term-cont-outer'>
        <div className='term-inner-cont'>
           <h2>Trust and Safety at RisoApp</h2>
           <p>At RisoApp, the safety and security of our users are our top priorities. We are committed to creating a trusted environment where users can engage with our services confidently. Here’s how we ensure your safety while using our platform:</p>
           <h2>1. Data Protection</h2>
           <p>We implement robust security measures to protect your personal information from unauthorized access, loss, or misuse. Our practices are in line with industry standards and comply with applicable data protection laws.
           </p>
           <h2>2. Privacy Policy</h2>
           <p>We respect your privacy and are transparent about how we collect, use, and protect your personal data. Our Privacy Policy outlines our practices, including your rights regarding your personal information.
           </p>
           <h2>3. User Verification</h2>
           <p>To maintain a safe community, we may implement verification processes for user accounts. This helps us ensure that our users are who they say they are, reducing the risk of fraudulent activity.
           </p>
           <h2>4. Reporting Mechanisms </h2>
           <p>If you encounter any suspicious behavior or believe that your safety is at risk, we encourage you to report it. Our dedicated support team is available to address your concerns promptly and effectively.
           </p>
           <h2>5. Educational Resources</h2>
           <p>We provide resources and tips to help our users recognize and avoid potential fraud, scams, and other risks. Stay informed and empowered to protect yourself online.
           </p>
           <h2>6. Continuous Improvement</h2>
           <p>We are constantly reviewing and updating our security protocols to address emerging threats. Our commitment to trust and safety is ongoing, and we welcome feedback from our community to improve our practices.
           </p>
           <h2>Your Safety Matters</h2>
           <p>At RisoApp, we believe that a safe and secure environment is essential for fostering trust and engagement among our users. We are dedicated to safeguarding your information and providing you with a positive experience on our platform.
           </p>
           <p>
           If you have any questions or concerns about trust and safety, please feel free to reach out to us at [support@risoapp.com]. Together, we can create a safer online community.

           </p>
        </div>
    </div>
  )
}

export default TrustContainer