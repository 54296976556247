import React from "react";
import Navbar from "../pagesections/Navbar";
import "./Termhero.css";
import PrivyHero from "../aboutuscomp/PrivyHero.jsx";

function Privhero() {
  return (
    <>
    <Navbar/>
    <div className="hero-sec-small">
        <Navbar/>
        <PrivyHero/>
    </div>
    </>
  );
}

export default Privhero;
