import React from "react";
import Herosec from "../pagesections/Herosec";
import Counter from "../counter/Counter";
import Searchjob from "../pagesections/Serchjob";
import Expertsec from "../pagesections/Expertsec";
import Programsec from "../pagesections/Programsec";
import Featuredcompany from "../pagesections/Featuredcompany";
import Footer from "../pagesections/Footer";
import "./appfooter.css";
import PopularSearchCategories from "../Popular_search/PopularSearchCategories";
import Ask from "../Ask_us/Ask";
import TopCompanies from "../Trending/TopCompanies";
import AllAsk from "../Ask_us/AllAsks";
import BlogsAll from "../Ask_us/BlogsAll";

function Blogs() {
  return (
    <div className="landing-page-container">
      <Herosec />
      {/* <Counter /> */}
      {/* <PopularSearchCategories/> */}
      <BlogsAll/>
      {/* <TopCompanies/> */}
      {/* <Featuredcompany /> */}
      <div className="footer-container-landingkl">
        <Footer />
      </div>
    </div>
  );
}

export default Blogs;
