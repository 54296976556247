import React from "react";
import "./Expertbanner.css";
import expertp from "../../assets/images/expertpanel.png";
import { useNavigate } from "react-router-dom";

function Expertbanner() {
  const navigate = useNavigate();
  const goToAbout = () => {
    navigate('/councellors');
  };
  return (
    <div className="expert-banner-conatiner-job">
      <div className="banner-content-job">
        <div className="expert-contaniner-job">
          <div className="banner-txt">
            100+ industry expert counsellors and AI successfully helping
            students and professionals to overcome their challenges and achieve
            goals.
          </div>
          <br/>
          <button className="srch-job-btn" onClick={goToAbout}>Talk to Experts</button>
        </div>
        <div className="expet-banner-img-container-job">
          <img src={expertp} alt="expert pannel" />
        </div>
      </div>
    </div>
  );
}

export default Expertbanner;
