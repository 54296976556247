import React from 'react'
import ContactHeroSec from '../pagesections/ContactHeroSec'
import Footer from '../pagesections/Footer'
import "./contact.css"
import ContactContainer from './contactContainer'

const Contactus = () => {
  return (
    
        <div className='contact-us'>
        <ContactHeroSec/>
        <ContactContainer/>
         <Footer/>

    </div>
  )
}

export default Contactus