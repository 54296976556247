import React from "react";
import Termhero from "../Termcomp/Termhero";
import Footer from "../pagesections/Footer";
import Aboutussec from "../aboutuscomp/Aboutussec";
import TermContainer from "../Termcomp/TermContainer";
import Privhero from "../Termcomp/PrivHero";
import PrivContainer from "../Termcomp/PrivContainer";
import FaqHero from "../Termcomp/Faqhero";
import FaqContainer from "../Termcomp/FaqContainer";
import FraudHero from "../Termcomp/Fraudhero";
import FraudContainer from "../Termcomp/FraudContainer";
import DeleteHero from "../Termcomp/DeleteHero";
import DeleteContainer from "../Termcomp/DeleteContainer";

function Delete() {
   
  return (
    <>
    <div className="terminology">
      <DeleteHero />
      {/* <Aboutussec/> */}
      <DeleteContainer/>
      </div>
      <div className="footer-container-term">
        <Footer />
      </div>
   
    </>
  );
}

export default Delete;
