// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vision-container {
  position: relative;
  top: 1197px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.vison-content-container {
  display: block;
  position: relative;
  width: 78%;
}
.vision-cards-container {
  display: flex;
  position: relative;
  /* left: 240px; */
  top: 80px;
  column-gap: 150px;
  width: 78%;
}

@media (max-width: 565px) {
  .vision-container {
    position: relative;
    top: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 20px;
  }
  .vison-content-container {
    display: block;
    position: relative;
    width: 100%;
  }
  .vision-cards-container {
    display: flex;
    flex-direction: column;
    position: relative;
    /* left: 240px; */
    top: 20px;
    column-gap: 150px;
    width: 100%;
    row-gap: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/aboutuscomp/Vision.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;AACxB;AACA;EACE,cAAc;EACd,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,SAAS;EACT,iBAAiB;EACjB,UAAU;AACZ;;AAEA;EACE;IACE,kBAAkB;IAClB,UAAU;IACV,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sBAAsB;IACtB,eAAe;EACjB;EACA;IACE,cAAc;IACd,kBAAkB;IAClB,WAAW;EACb;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,iBAAiB;IACjB,SAAS;IACT,iBAAiB;IACjB,WAAW;IACX,aAAa;EACf;AACF","sourcesContent":[".vision-container {\n  position: relative;\n  top: 1197px;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n.vison-content-container {\n  display: block;\n  position: relative;\n  width: 78%;\n}\n.vision-cards-container {\n  display: flex;\n  position: relative;\n  /* left: 240px; */\n  top: 80px;\n  column-gap: 150px;\n  width: 78%;\n}\n\n@media (max-width: 565px) {\n  .vision-container {\n    position: relative;\n    top: 350px;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    padding: 0 20px;\n  }\n  .vison-content-container {\n    display: block;\n    position: relative;\n    width: 100%;\n  }\n  .vision-cards-container {\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    /* left: 240px; */\n    top: 20px;\n    column-gap: 150px;\n    width: 100%;\n    row-gap: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
