import React, { useState } from "react";
import prgrm from "../../assets/images/program.png";
import "./Programsec.css";
import Comming from "../Commingsoon/Comming";

function Programsec() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="progrm-job-container">
      <div className="srch-job-content">
        <div className="src-job-text">
          <div className="src-job-text-head">
            Enhance your social well-being to foster personal growth
          </div>
          <div className="src-job-text-desc">Explore industry-led programs</div>
          <button className="srch-job-btn" onClick={handleOpen}>Explore Programs</button>
        </div>
        <div className="src-job-img">
          <img src={prgrm} alt="job" />
        </div>
      </div>
      <Comming open={open} handleClose={handleClose} />
    </div>
  );
}

export default Programsec;
