import React from 'react'
import Navbar from './Navbar'
import Herocontentl from './Herocontentl'
import HerocontentC from './HerocontentC'

function ContactHeroSec() {
  return (
    <div className='hero-sec-container1'>
        <Navbar/>
        <HerocontentC/>
    </div>
  )
}

export default ContactHeroSec