import React from "react";
import "./Navbar.css";
import menu from "../../assets/images/menu-icon.svg";
import logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom"; // Use NavLink instead of Link
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import FeedIcon from "@mui/icons-material/Feed";
import RecentActorsIcon from "@mui/icons-material/RecentActors";

function Navbar() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          { to: "/", label: "Home", icon: <HomeIcon /> },
          { to: "/about-us", label: "About Us", icon: <InfoOutlinedIcon /> },
          { to: "/services", label: "Services", icon: <HomeRepairServiceIcon /> },
          { to: "/blogs", label: "Blogs", icon: <FeedIcon /> },
          { to: "/contact-us", label: "Contact Us", icon: <RecentActorsIcon /> },
        ].map((item, index) => (
          <ListItem key={index}>
            <NavLink
              to={item.to}
              className={({ isActive }) => (isActive ? "active-link" : "inactive-link")}
            >
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <div className="nabvar-container">
        <div className="navbar">
          <div className="nav-menu-icon">
            <img src={menu} alt="navigation-menu-icon" />
          </div>
          <div className="nav-logo-container">
            <NavLink to="/">
              <img src={logo} alt="riso-logo" />
            </NavLink>
          </div>
          <div className="nav-items">
            <div className="menu-items">
              <NavLink to="/" className={({ isActive }) => (isActive ? "active-link" : "")}>
                Home
              </NavLink>
              <NavLink to="/about-us" className={({ isActive }) => (isActive ? "active-link" : "")}>
                About Us
              </NavLink>
              <NavLink to="/services" className={({ isActive }) => (isActive ? "active-link" : "")}>
                Services
              </NavLink>
              <NavLink to="/blogs" className={({ isActive }) => (isActive ? "active-link" : "")}>
                Blogs
              </NavLink>
              <NavLink to="/contact-us" className={({ isActive }) => (isActive ? "active-link" : "")}>
                Contact Us
              </NavLink>
            </div>
          
          </div>
          {/* <div className="menu-btn">
                 <button>Ask us</button>
            </div> */}
        </div>
        
      </div>

      {/* Mobile Navbar */}
      <div className="mobile_nav_container123">
        <div className="mobile_nav_logo_section123">
          <img src={logo} alt="logo" />
        </div>
        <div>
          <React.Fragment>
            <div className="mob_Icons_arrange">
              <MenuIcon onClick={toggleDrawer("left", true)} />
            </div>
            <Drawer anchor={"left"} open={state["left"]} onClose={toggleDrawer("left", false)}>
              {list("left")}
            </Drawer>
          </React.Fragment>
        </div>
      </div>
    </>
  );
}

export default Navbar;
