import React from "react";
import "./Mission.css";

function Mission() {
  return (
    <div className="mission-container">
      <div className="sec-content-container">
        <div className="sec-title">Our Mission</div>
        <div className="mission-content">
          <div className="text-l">
            To empower individuals by providing adequate educational guidance,
            career coaching and mental wellness through counseling and remedial
            sessions.
          </div>
          <div className="text-r">
            To bestow them highly personalized and optimized educational
            guidance, career coaching and mental wellness services which focuses
            on the needs of every Individual.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
