import React from "react";
import Termhero from "../Termcomp/Termhero";
import Footer from "../pagesections/Footer";
import Aboutussec from "../aboutuscomp/Aboutussec";
import TermContainer from "../Termcomp/TermContainer";
import Privhero from "../Termcomp/PrivHero";
import PrivContainer from "../Termcomp/PrivContainer";
import FaqHero from "../Termcomp/Faqhero";
import FaqContainer from "../Termcomp/FaqContainer";

function Faq() {
  return (
    <>
    <div className="terminology">
      <FaqHero />
      {/* <Aboutussec/> */}
      <FaqContainer/>
      </div>
      <div className="footer-container-term">
        <Footer />
      </div>
   
    </>
  );
}

export default Faq;
