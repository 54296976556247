import React from "react";
import "../Termcomp/Termhero.css";
import bar from "../../assets/images/herobar.svg";

function TrusttHero() {
  return (
    <div className="hero-content-term-container">
      <div className="term-header-sec">
      <h2>Trust and saftey</h2>
      </div>
    </div>
  );
}

export default TrusttHero;
