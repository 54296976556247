import React from "react";
import abt from "../../assets/images/aboutus.png";
import "./Aboutussec.css";

function Aboutussec() {
  return (
    <div className="abt-container">
      <div className="abt-content">
        <div className="abt-text">
          <div className="abt-text-head">About Us</div>
          <div className="abt-text-desc">
            RisoApp is designed to be a comprehensive, AI-driven counseling
            platform that supports individuals throughout their entire
            educational and career journey. We provide personalized support to
            help individuals identify their strengths, achieve their goals, and
            realize their full potential in all aspects of their lives.
          </div>
        </div>
        <div className="abt-img">
          <img src={abt} alt="job" />
        </div>
      </div>
    </div>
  );
}

export default Aboutussec;
