import React from "react";
import Herosec from "../pagesections/Herosec";
import Counter from "../counter/Counter";
import Searchjob from "../pagesections/Serchjob";
import Expertsec from "../pagesections/Expertsec";
import Programsec from "../pagesections/Programsec";
import Featuredcompany from "../pagesections/Featuredcompany";
import Footer from "../pagesections/Footer";
import "./appfooter.css";
import PopularSearchCategories from "../Popular_search/PopularSearchCategories";
import Ask from "../Ask_us/Ask";
import TopCompanies from "../Trending/TopCompanies";

function Services() {
  return (
    <div className="landing-page-container">
      <Herosec />
      <Counter />
      <PopularSearchCategories/>
      <Ask/>
      <TopCompanies/>
     <div className="adj"><Featuredcompany /></div> 
      <div className="footer-container-landing-ser">
        <Footer />
      </div>
    </div>
  );
}

export default Services;
