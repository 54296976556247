import React, { useState } from "react";
import "./Expertsec.css";
import exprt from "../../assets/images/expert.png";
import Expertbanner from "../banners/Expertbanner";
import Comming from "../Commingsoon/Comming";

function Expertsec() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="expert-container">
       <div className="expert-inner">
       <div className="expert-content">
        <div className="expertm-img">
          <img src={exprt} alt="job" />
        </div>
        <div className="expert-text">
          <div className="expert-text-head">
            Choose the best career path with experts
          </div>
          <div className="expert-text-desc">
            Find out newest industry programs, workshops, news, and community
            activities.
          </div>
          <button className="srch-job-btn" onClick={handleOpen}>Explore Programs</button>
        </div>
      </div>
       </div>
    
        <Expertbanner />
        <Comming open={open} handleClose={handleClose} />
    </div>
  );
}

export default Expertsec;
