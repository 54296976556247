import React from "react";
import Herosec from "../pagesections/Herosec";
import Counter from "../counter/Counter";
import Searchjob from "../pagesections/Serchjob";
import Expertsec from "../pagesections/Expertsec";
import Programsec from "../pagesections/Programsec";
import Featuredcompany from "../pagesections/Featuredcompany";
import Footer from "../pagesections/Footer";
import "./appfooter.css";

function Landing() {
  return (
    <div className="landing-page-container">
      <Herosec />
      <Counter />
      <Searchjob />
      <Expertsec />
      <Programsec />
      <Featuredcompany />
      <div className="footer-container-landing">
        <Footer />
      </div>
    </div>
  );
}

export default Landing;
