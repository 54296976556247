// App.js
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Aboutus from "./components/screens/Aboutus";
import Contactus from "./components/screens/Contactus";
import Faq from "./components/screens/Faq";
import Fraud from "./components/screens/Fraud";
import Landing from "./components/screens/Landing";
import Privacy from "./components/screens/Privacy";
import Termpolicy from "./components/screens/Termpolicy";
import Trust from "./components/screens/Trust";
import Services from "./components/screens/Services";
import Councellor from "./components/screens/Councellor";
import Blogs from "./components/screens/Blogs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Delete from "./components/screens/Delete";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/terms" element={<Termpolicy />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/fraud" element={<Fraud />} />
          <Route path="/services" element={<Services />} />
          <Route path="/trust" element={<Trust />} />
          <Route path="/councellors" element={<Councellor />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/delete" element={<Delete />} />
        </Routes>
        <ToastContainer/>
      </div>
    </Router>
  );
}

export default App;
