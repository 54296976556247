import React from "react";
import Termhero from "../Termcomp/Termhero";
import Footer from "../pagesections/Footer";
import Aboutussec from "../aboutuscomp/Aboutussec";
import TermContainer from "../Termcomp/TermContainer";

function Termpolicy() {
  return (
    <>
    <div className="terminology">
      <Termhero />
      {/* <Aboutussec/> */}
      <TermContainer/>
      </div>
      <div className="footer-container-term">
        <Footer />
      </div>
   
    </>
  );
}

export default Termpolicy;
