import React from "react";
import Termhero from "../Termcomp/Termhero";
import Footer from "../pagesections/Footer";
import Aboutussec from "../aboutuscomp/Aboutussec";
import TermContainer from "../Termcomp/TermContainer";
import TrustHero from "../Termcomp/TrustHero";
import TrustContainer from "../Termcomp/TrustContainer";

function Trust() {
  return (
    <>
    <div className="terminology">
      <TrustHero />
      {/* <Aboutussec/> */}
      <TrustContainer/>
      </div>
      <div className="footer-container-term">
        <Footer />
      </div>
   
    </>
  );
}

export default Trust;
