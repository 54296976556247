import React from "react";
import Navbar from "../pagesections/Navbar";
import "./Termhero.css";
import Herocontenta from "../aboutuscomp/Herocontenta";
import TermHeroH from "../aboutuscomp/TermHeroH.jsx";
import TermHeroHero from "../aboutuscomp/TermHeroH.jsx";

function Termhero() {
  return (
    <>
    <Navbar/>
    <div className="hero-sec-small">
        <Navbar/>
        <TermHeroHero/>
    </div>
    </>
  );
}

export default Termhero;
