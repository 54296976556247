// Comming.js
import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import bImg from "../../assets/images/heroimg.png";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

function Comming({ open, handleClose }) {
  return (
    <Modal
      keepMounted
      open={open}
      onClose={handleClose}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={style}>
        <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
          Coming soon...
        </Typography>
        <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
          <div className='cmImg'>
               <img src={bImg}/>
          </div>
        </Typography>
      </Box>
    </Modal>
  );
}

export default Comming;
