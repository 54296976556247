import React from 'react';
import "./Termhero.css";

function FraudContainer() {
  return (
    <div className='term-cont-outer'>
        <div className='term-inner-cont'>
            <p><strong>Dear RisoApp Users,</strong></p>
            <p>We want to ensure that your experience with RisoApp is safe and secure. Please be vigilant and aware of potential fraud attempts that may target users of our platform.</p>
            <h2>Common Fraud Scenarios:</h2>
            <ul>
              <li><p>
              <strong>Phishing Emails:</strong> Be cautious of unsolicited emails claiming to be from RisoApp. These emails may ask for personal information or direct you to a fraudulent website. Always verify the sender’s email address.
                </p></li>
                <li>
                  <p>
                  <strong>Unauthorized Transactions:</strong> Monitor your accounts for any unauthorized transactions or suspicious activity. If you notice anything unusual, please report it immediately.
                  </p>
                </li>
                <li>
                  <p>
                  <strong>Impersonation Attempts:</strong> Fraudsters may impersonate RisoApp staff or representatives to gain access to your personal information. Always verify the identity of anyone requesting sensitive information.
                  </p>
                </li>
                <li>
                  <p>
                  <strong>Job Offers and Recruitment Scams:</strong> Be wary of job offers that seem too good to be true, especially those requesting payment or personal information upfront. RisoApp will never ask for payment in exchange for a job offer.

                  </p>
                </li>
            </ul>
            <h2>What You Can Do:</h2>

            <ul>
              <li>
              <p><strong>Verify Information:</strong> If you receive any communication that seems suspicious, please verify by contacting our support team directly through official channels.
              </p>
              </li>
              <li><p><strong>Protect Your Information:</strong> Never share your account details, passwords, or personal information with anyone.</p></li>
              <li><p><strong>Report Suspicious Activity:</strong> If you encounter any fraudulent activity or have concerns, please report it to us immediately at [support@risoapp.com].
              </p></li>
            </ul>
            <p><strong>Stay Safe!</strong> Your security is our priority. We are committed to protecting your personal information and ensuring a safe platform for all users.
            </p>

            <p>Thank you for being a valued member of the RisoApp community.</p>
            <p><strong>Best Regards,</strong></p>
            <p>The RisoApp Team</p>


        </div>
    </div>
  )
}

export default FraudContainer