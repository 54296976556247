import React, { useState } from "react";
import "./Searchjob.css";
import srch from "../../assets/images/srchjob.png";
import Srchjobbanner from "../banners/Srchjobbanner";
import Comming from "../Commingsoon/Comming";

function Serchjob() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="srch-job-container">
     <div className="srch-inner">
     <div className="srch-job-content">
        <div className="src-job-text">
          <div className="src-job-text-head">
            Find the right job or internship for you
          </div>
          <div className="src-job-text-desc">
            Explore 5 lakh+ career opportunities.
          </div>
          <button onClick={handleOpen} className="srch-job-btn">Search Job</button>
        </div>
        <div className="src-job-img">
          <img src={srch} alt="job" />
        </div>
      </div>
     </div>
      <div className="srch-job-banner">
        <Srchjobbanner />
      </div>
      <Comming open={open} handleClose={handleClose} />
    </div>
  );
}

export default Serchjob;
