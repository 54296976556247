import React from "react";
import Termhero from "../Termcomp/Termhero";
import Footer from "../pagesections/Footer";
import Aboutussec from "../aboutuscomp/Aboutussec";
import TermContainer from "../Termcomp/TermContainer";
import Privhero from "../Termcomp/PrivHero";
import PrivContainer from "../Termcomp/PrivContainer";

function Privacy() {
  return (
    <>
    <div className="terminology">
      <Privhero />
      {/* <Aboutussec/> */}
      <PrivContainer/>
      </div>
      <div className="footer-container-term">
        <Footer />
      </div>
   
    </>
  );
}

export default Privacy;
