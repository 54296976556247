import React from "react";
import Navbar from "../pagesections/Navbar";
import "./Termhero.css";
import Herocontenta from "../aboutuscomp/Herocontenta";
import TermHeroH from "../aboutuscomp/TermHeroH.jsx";
import TermHeroHero from "../aboutuscomp/TermHeroH.jsx";
import FaqqHero from "../aboutuscomp/FaqqHero.jsx";
import FrauddHero from "../aboutuscomp/FrauddHero.jsx";
import DeleteddHero from "../aboutuscomp/DetetedHEro.jsx";

function DeleteHero() {
  return (
    <>
    <Navbar/>
    <div className="hero-sec-small">
        <Navbar/>
        <DeleteddHero/>
    </div>
    </>
  );
}

export default DeleteHero;
