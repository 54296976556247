import React from "react";
import Navbar from "../pagesections/Navbar";
import "./Termhero.css";
import Herocontenta from "../aboutuscomp/Herocontenta";
import TermHeroH from "../aboutuscomp/TermHeroH.jsx";
import TermHeroHero from "../aboutuscomp/TermHeroH.jsx";
import FaqqHero from "../aboutuscomp/FaqqHero.jsx";
import FrauddHero from "../aboutuscomp/FrauddHero.jsx";

function FraudHero() {
  return (
    <>
    <Navbar/>
    <div className="hero-sec-small">
        <Navbar/>
        <FrauddHero/>
    </div>
    </>
  );
}

export default FraudHero;
