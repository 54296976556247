import React from "react";
import "./Footer.css";
import play from "../../assets/images/Google_Play_Store_badge_EN_1.png";
import apple from "../../assets/images/Group_165074.png";
import riso from "../../assets/images/Group_165071.png";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer-container">
      <footer className="ftr_container">
        <div className="ftr_hd_1">
          <div className="ftr_abt">
            <h3>Risoapp India Pvt Ltd</h3>
            <p>
            RisoApp is an AI-powered platform for education and career counseling, offering personalized guidance from school to professional life. Combining AI insights with expert support, RisoApp serves students, professionals, parents, and institutions.
            </p>
          </div>
          <div className="ftr_link_main">
            <div className="ftr_lnk">
              <Link to="/about-us">About Riso</Link>
              <Link to="/services">Riso Services</Link>
              <a href="https://risohire.com/">Work at Riso</a>
              <Link to="/blogs">Blogs</Link>
              <Link to="/contact-us">Contact Us</Link>
              <Link to="/contact-us">Help & Support</Link>
            </div>
            <div className="ftr_lnk1">
              <a><Link to="/privacy">Privacy Policy</Link></a>
              <a><Link to="/terms">Terms & Conditions</Link></a>
              <a><Link to="/faq">FAQ</Link></a>
              <a><Link to="/fraud">Fraud Alert</Link></a>
              <a><Link to="/trust">Trust & Safety</Link></a>
              <Link to="/delete">Delete Account</Link>
            </div>
          </div>
          <div className="ftr_lnk2">
            <div className="ftr_app">
              <img src={play} alt="plastore image"></img>
              <img src={apple} alt="apple store"></img>
            </div>
            <div className="ftr_follow_us">
              <img src={riso} alt="riso logo" className="logo_main"></img>
              <div className="ftr_follow_2">
                <h3>Follow us on social media</h3>
                <div className="ftr_icons">
                  <i>
                    <FaFacebookF />
                  </i>
                  <i>
                    <a href="https://www.linkedin.com/company/risoapp/"><FaLinkedinIn /></a>
               
                  </i>
                  <i>
                    <a href="https://x.com/app_riso"><FaXTwitter /></a>
                  
                  </i>
                  <i>
                    <FaInstagram />
                  </i>
                  <i>
                    <a href="https://www.youtube.com/channel/UCBVAGRFnPEt9PQ8DLWycOGg"> <FaYoutube /></a>
                   
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ftr_hd_2">
          <p>
            All trademarks are the property of their respective owners. All
            rights received © 2024 Risoapp India Pvt Ltd.{" "}
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
