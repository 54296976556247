import React from "react";
import "./Herocontentl.css";
import hero from "../../assets/images/heroimg.png";
import navdot from "../../assets/images/hero-nav.svg";
import { useNavigate } from "react-router-dom";

function Herocontentl() {
  const navigate = useNavigate();
  const goToAbout = () => {
    navigate('/councellors');
  };
  return (
    <div className="hero-container">
      <div className="hero-sec-content">
        <div className="hero-img">
          <img src={hero} alt="riso app img" />
        </div>
        
        <div className="hero-text-container">
          <div className="hero-text">
            Discover your perfect career path with our expert counsellors and AI
          </div>
          <button className="talk-expert-btn" onClick={goToAbout}>Talk to Experts</button>
        </div>
      </div>
    </div>
  );
}

export default Herocontentl;
