import React from "react";
import "./Vision.css";
import Visioncrad from "./Visioncrad";

function Vision() {
  return (
    <div className="vision-container">
      <div className="vison-content-container">
        <div className="sec-title">Our Vision</div>
        <div className="vision-cards-container">
          <Visioncrad />
          <Visioncrad />
          <Visioncrad />
        </div>
      </div>
    </div>
  );
}

export default Vision;
