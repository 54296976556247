// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container-landing {
  position: relative;
  top: 1530px;
}

.footer-container-landing-ser {
  position: relative;
  top: 1530px;
}
.footer-container-about {
  position: relative;
  top: 1330px;
}

.footer-container-landingkl{
  position: relative;
  top: 1000px;
}


@media (max-width: 565px) { 

  .footer-container-landing {
    position: relative;
    top: 1000px;
  }

  .footer-container-landing-ser {
    position: relative;
    top: 500px;
  }
  .footer-container-about {
    position: relative;
    top: 501px;
  }
  
  .footer-container-landingkl{
    position: relative;
    top: 250px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/screens/appfooter.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,WAAW;AACb;;;AAGA;;EAEE;IACE,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,kBAAkB;IAClB,UAAU;EACZ;EACA;IACE,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,UAAU;EACZ;AACF","sourcesContent":[".footer-container-landing {\n  position: relative;\n  top: 1530px;\n}\n\n.footer-container-landing-ser {\n  position: relative;\n  top: 1530px;\n}\n.footer-container-about {\n  position: relative;\n  top: 1330px;\n}\n\n.footer-container-landingkl{\n  position: relative;\n  top: 1000px;\n}\n\n\n@media (max-width: 565px) { \n\n  .footer-container-landing {\n    position: relative;\n    top: 1000px;\n  }\n\n  .footer-container-landing-ser {\n    position: relative;\n    top: 500px;\n  }\n  .footer-container-about {\n    position: relative;\n    top: 501px;\n  }\n  \n  .footer-container-landingkl{\n    position: relative;\n    top: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
