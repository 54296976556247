import React, { useState } from "react";
import "./Srchjobbanner.css";
import Comming from "../Commingsoon/Comming";

function Srchjobbanner() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="banner-conatiner">
      <div className="banner-content">
        <div className="banner-txt">
          Post your job for millions of people to see and find the right
          candidate for your needs.
        </div>
        <div className="bannen-middle"></div>
        <button className="srch-job-btn" onClick={handleOpen}>Post Job</button>
      </div>
      <Comming open={open} handleClose={handleClose} />
    </div>
  );
}

export default Srchjobbanner;
