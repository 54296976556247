import React from "react";
import "./Herocontentl.css";
import hero from "../../assets/images/heroimg.png";
import navdot from "../../assets/images/hero-nav.svg";

function HerocontentB() {
  return (
    <div className="hero-container-contact">
      <div className="hero-sec-content-contact">
          <h2>Our Blogs</h2>
          <p>You can talk to us, ask quetions or report a issue from here.</p>
      </div>
    </div>
  );
}

export default HerocontentB;
