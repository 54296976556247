import React, { useState } from 'react'
import "./contact.css"
import { FaCalendarPlus, FaMailBulk, FaPhone, FaPhoneAlt } from 'react-icons/fa'
import { FaLocationPin, FaPhoneFlip } from 'react-icons/fa6'
import { useContactSlotMutation } from '../../slice/authApiSlice';
import { toast, ToastContainer } from 'react-toastify';

function ContactContainer() {

  const [contactSlot, { isLoading: bookLoading }] = useContactSlotMutation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phNumber: '',
        text: '',
    });
    

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async() => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!formData.name || !formData.email || !formData.phNumber || !formData.text) {
          toast.error('All fields are required.');
          return;
        }
    
        if (!emailRegex.test(formData.email)) {
          toast.error('Please enter a valid email address.');
          return;
        }
    
        if (formData.phNumber.length !== 10 || isNaN(formData.phNumber)) {
          toast.error('Phone number must be 10 digits.');
          return;
        }
        try {
            const res = await contactSlot(formData).unwrap();
            if (res) {
              toast.success('Message sent successfully!');
              // Reset form fields
              setFormData({ name: '', email: '', phNumber: '', text: '' });
            }
          } catch (error) {
            setFormData({ name: '', email: '', phNumber: '', text: '' });
            console.error('Error sending message:', error);
            toast.error('Failed to send message. Please try again.');
          }
    };
  return (
    <div className='contact-main'>
        <div className='contact-inner'>
            <div className='contact-left'>
                <div className='contact-card'>
                  <div className='ic'>
                  <FaPhoneAlt/>
                  </div>
                   <div className='contact-ic-content'>
                      <p>Talk to our executive</p>
                      <h5>+91 76761 87272</h5>
                   </div>
                </div>
                <div className='contact-card'>
                  <div className='ic'>
                  <FaMailBulk/>
                  </div>
                   <div className='contact-ic-content'>
                      <p>E-mail us at</p>
                      <h5>support@risoapp.com</h5>
                   </div>
                </div>
                <div className='contact-card'>
                  <div className='ic'>
                  <FaLocationPin/>
                  </div>
                   <div className='contact-ic-content'>
                      <p>Official Location</p>
                      <h5>Neeladri Investment Layout Doddathogur Electronic City Bengaluru, 560100</h5>
                   </div>
                </div>
            </div>
            <div className='contact-right'>
            <div className='right-card-cont'>
                <div className='contact-header'>
                    <h2>Contact Us</h2>
                </div>
                <div className='contact-signup-cont'>
                    <input
                        type='text'
                        name='name'
                        placeholder='Enter your name'
                        value={formData.name}
                        onChange={handleChange}
                    />
                    <input
                        type='text'
                        name='email'
                        placeholder='Enter your email address'
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <input
                        type='text'
                        name='phNumber'
                        placeholder='Enter your phone number'
                        value={formData.phNumber}
                        onChange={handleChange}
                    />
                    <textarea
                        name='text'
                        placeholder='Enter description'
                        value={formData.text}
                        onChange={handleChange}
                    />
                    <button onClick={handleSubmit}>Send</button>
                </div>
            </div>
        </div>
        </div>
        <ToastContainer/>
    </div>
  )
}

export default ContactContainer