import React from "react";
import Herosec from "../pagesections/Herosec";
import Heroabout from "../aboutuscomp/Heroabout";
import Aboutussec from "../aboutuscomp/Aboutussec";
import Mission from "../aboutuscomp/Mission";
import Vision from "../aboutuscomp/Vision";
import Aboutcount from "../aboutuscomp/Aboutcount";
import Footer from "../pagesections/Footer";

function Aboutus() {
  return (
    <div className="about-us-container">
      <Heroabout />
      <Aboutussec />
      <Mission />
      <Vision />
      <Aboutcount />
      <div className="footer-container-about">
        <Footer />
      </div>
    </div>
  );
}

export default Aboutus;
