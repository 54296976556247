// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.counter-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 760px;
  width: 100%;
}
.counter-content {
  display: flex;
  position: relative;
  /* left: 240px; */
  column-gap: 60px;
  width: 78%;
}
.counter-content section p {
  font-family: Roboto;
  font-size: 38px;
  font-weight: 200;
  line-height: normal;
  letter-spacing: 0em;
  font-variation-settings: "opsz" auto;
  color: #3d3d3d;
}
@media (max-width: 565px) {
  .counter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 120px;
    width: 100%;
  }
  .counter-content {
    display: flex;
    position: relative;
    /* left: 240px; */
    column-gap: 60px;
    width: 100%;
    padding: 0 20px;
    /* flex-direction: column; */
    flex-wrap: wrap;
    row-gap: 20px;
    /* column-gap: 20px; */
  }
  .counter-content section p {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 200;
    line-height: normal;
    letter-spacing: 0em;
    font-variation-settings: "opsz" auto;
    color: #3d3d3d;
   
  }
}`, "",{"version":3,"sources":["webpack://./src/components/counter/Counter.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,UAAU;AACZ;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,oCAAoC;EACpC,cAAc;AAChB;AACA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,UAAU;IACV,WAAW;EACb;EACA;IACE,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,4BAA4B;IAC5B,eAAe;IACf,aAAa;IACb,sBAAsB;EACxB;EACA;IACE,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,oCAAoC;IACpC,cAAc;;EAEhB;AACF","sourcesContent":[".counter-container {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: relative;\n  top: 760px;\n  width: 100%;\n}\n.counter-content {\n  display: flex;\n  position: relative;\n  /* left: 240px; */\n  column-gap: 60px;\n  width: 78%;\n}\n.counter-content section p {\n  font-family: Roboto;\n  font-size: 38px;\n  font-weight: 200;\n  line-height: normal;\n  letter-spacing: 0em;\n  font-variation-settings: \"opsz\" auto;\n  color: #3d3d3d;\n}\n@media (max-width: 565px) {\n  .counter-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    position: relative;\n    top: 120px;\n    width: 100%;\n  }\n  .counter-content {\n    display: flex;\n    position: relative;\n    /* left: 240px; */\n    column-gap: 60px;\n    width: 100%;\n    padding: 0 20px;\n    /* flex-direction: column; */\n    flex-wrap: wrap;\n    row-gap: 20px;\n    /* column-gap: 20px; */\n  }\n  .counter-content section p {\n    font-family: Roboto;\n    font-size: 18px;\n    font-weight: 200;\n    line-height: normal;\n    letter-spacing: 0em;\n    font-variation-settings: \"opsz\" auto;\n    color: #3d3d3d;\n   \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
