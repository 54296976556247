import React, { useState } from "react";
import "./PopularSearchCategories.css";
import hire_arrow from "../../assets/images/hire_arrow.png";
import { usePopularDetQuery } from "../../slice/authApiSlice";
// import MobilePopSearch from "./mpopularsearch/MobilePopSearch";
// import { usePopularDetQuery } from "../../slice/authApiSlice";

const PopularSearchCategories = () => {
  const { data: popularDet, isLoading: isLoadingpopularDet, error: popularError } = usePopularDetQuery();

  // State to control whether to show all categories or only a few
  const [showAll, setShowAll] = useState(false);

  // Toggling between showing limited and full list
  const handleViewMore = () => {
    setShowAll((prevState) => !prevState);
  };

  // Determine how many categories to show based on state
  const categoriesToShow = showAll ? popularDet?.data : popularDet?.data;

console.log(categoriesToShow);


  return (
    <div className="popular_search_categories_section">
      <div className="popular_search_categories_container">
        <section className="inner_popular_sec">
        <h1 className="section_heading">Popular search categories</h1>
        <div className="categories_section">
          <div className="categories_container">
            {categoriesToShow?.map((category, index) => (
              <div className="category" key={index}>
                <div className="category_text_section">
                  <h5 className="ctaegory_title">{category.name}</h5>
                  <p>{category.opportunities} Opportunities</p>
                </div>
                <div className="p_hire_arrow_section">
                  <img src={hire_arrow} alt="arrow_right" />
                </div>
              </div>
            ))}
          </div>
          <div className="viewmore_button_container">
            {/* <button onClick={handleViewMore}>
              {showAll ? "View Less" : "View More"}
            </button> */}
          </div>
        </div>
        </section>
      </div>
      <div className="mps_container_section">
        {/* <MobilePopSearch /> */}
      </div>
    </div>
  );
};

export default PopularSearchCategories;
