import React from 'react';
import "./Termhero.css";

function FaqContainer() {
  return (
    <div className='term-cont-outer'>
        <div className='term-inner-cont'>
          <h2>1. What is RisoApp?</h2>
          <p>
          RisoApp is a comprehensive platform designed to provide AI-driven counseling services for education and career guidance. We aim to empower users with personalized insights and resources to help them navigate their educational and professional journeys.
          </p>
          <h2>2. How does RisoApp protect my personal information?</h2>
          <p>
          At RisoApp, we take data protection seriously. We implement robust security measures to safeguard your personal information, adhere to applicable data protection laws, and maintain a transparent Privacy Policy.
          </p>
          <h2>3. Do I need to create an account to use RisoApp?</h2>
          <p>
          Yes, creating an account allows you to access personalized features, track your progress, and receive tailored recommendations. Registration is quick and easy!
          </p>
          <h2>4. Can I delete my RisoApp account?</h2>
          <p>
          Yes, you can delete your account at any time. Simply go to your account settings and follow the instructions to delete your account permanently.
          </p>
          <h2>5. What types of services does RisoApp offer?</h2>
          <p>
          RisoApp offers a range of services, including personalized counseling, career assessments, educational resources, and AI-driven insights to help you make informed decisions about your education and career.
          </p>
          <h2>6. How does RisoApp use cookies?</h2>
          <p>
          We use cookies to enhance your experience on our website. Cookies help us understand user preferences, improve our services, and deliver personalized content. You can manage your cookie preferences through your browser settings.
          </p>
          <h2>7. How can I report suspicious activity on RisoApp?</h2>
          <p>
          If you notice any suspicious behavior or have concerns about your safety while using RisoApp, please report it to our support team at [support@risoapp.com]. Your safety is our priority, and we take such matters seriously.
          </p>
          <h2>8. Is there a fee for using RisoApp?</h2>
          <p>
          RisoApp offers various services, some of which may be free while others may require a subscription or payment. Please refer to our pricing page for detailed information about the services and associated costs.
          </p>
          <h2>9. How can I contact RisoApp support?</h2>
          <p>You can reach our support team via email at [support@risoapp.com]. We are here to assist you with any questions or concerns you may have.</p>
          <h2>10. Where can I find more information about RisoApp?</h2>
          <p>
          For more information about our services, features, and updates, please explore our website or follow us on our social media channels.
          </p>
        </div>
    </div>
  )
}

export default FaqContainer