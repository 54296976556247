// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Kumbh Sans", sans-serif;
  height: auto;
}

strong{
  font-weight: bold;
  /* color: #007EB9; */
}

.linkstrong{
  font-weight: bold;
  color: #007EB9;
}

.cmImg{
  width: 100%;
  height: 300px;
}
.cmImg img{
  width: 100%;
  height: 100%;
  object-fit: contain;
}

body {
  background-color: #f8f8f8;
  height: auto;
}

a {
  color: unset;
  text-decoration: none;
}

.section_padding {
  padding: 4rem 11rem;
}

.section_margin {
  margin: 4rem 6rem;
}


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAIA;EACE,sBAAsB;EACtB,UAAU;EACV,SAAS;EACT,uBAAuB;EACvB,qCAAqC;EACrC,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;AACf;AACA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Poppins&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Montserrat&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Roboto&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap\");\n* {\n  box-sizing: border-box;\n  padding: 0;\n  margin: 0;\n  scroll-behavior: smooth;\n  font-family: \"Kumbh Sans\", sans-serif;\n  height: auto;\n}\n\nstrong{\n  font-weight: bold;\n  /* color: #007EB9; */\n}\n\n.linkstrong{\n  font-weight: bold;\n  color: #007EB9;\n}\n\n.cmImg{\n  width: 100%;\n  height: 300px;\n}\n.cmImg img{\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\nbody {\n  background-color: #f8f8f8;\n  height: auto;\n}\n\na {\n  color: unset;\n  text-decoration: none;\n}\n\n.section_padding {\n  padding: 4rem 11rem;\n}\n\n.section_margin {\n  margin: 4rem 6rem;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
