import React from "react";
import "../aboutuscomp/Herocontenta.css";
import bar from "../../assets/images/herobar.svg";
import { useNavigate } from "react-router-dom";

function Herocontenta() {
  const navigate = useNavigate();
  const goToAbout = () => {
    navigate('/councellors');
  };
  return (
    <div className="hero-content-about-container">
      <div className="hero-text-l">Career Education Social </div>
      <div className="hero-bar">
        <img src={bar} alt="hero bar" />
      </div>
      <div className="about-hero-text-container">
        <div className="about-hero-text">
          Discover your perfect career path with our expert counsellors and AI
        </div>
        <button className="about-expert-btn" onClick={goToAbout}>Talk to Experts</button>
      </div>
    </div>
  );
}

export default Herocontenta;
