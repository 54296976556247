import React from 'react';
import "./Termhero.css";
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, description) {
  return { name, description };
}

const rows = [
  createData('Essential Cookies', 'Essential cookies (First Party Cookies) are sometimes called "strictly necessary" as without them we cannot provide many services that you need on the Website. For example, essential cookies help remember your preferences as you move around the Website. Essential cookies also keep you logged in into a website. Without them the login functionality would not work.'),
  createData('Analytics Cookies', 'These cookies track information about visits to the risoapp.com and partner (recruiter websites) websites so that we can make improvements and report our performance. For example: analyse visitor and user behaviour so as to provide more relevant content or suggest certain activities. They collect information about how visitors use the Websites, which site the user came from, the number of each user’s visits and how long a user stays on the Websites. We might also use analytics cookies to test new ads, pages, or features to see how users react to them.'),

  createData('Functionality or Preference Cookies', 'During your visit to the Websites, cookies are used to remember information you have entered or choices you make (such as your username, language or your region) on the Websites. They also store your preferences when personalizing the Websites to optimize your use of risoapp.com. These preferences are remembered, through the use of the persistent cookies, and the next time you visit the Websites you will not have to set them again.'),
  createData('Targeting or Advertising Cookies', 'These Cookies are placed by third party advertising platforms or networks or risoapp.com in order to, deliver ads and track ad performance, enable advertising networks to deliver ads that may be relevant to you based upon your activities (this is sometimes called "behavioural" "tracking" or "targeted" advertising) on the Websites. They may subsequently use information about your visit to target you with advertising that you may be interested in, on risoapp.com websites or other websites. For example, these cookies remember which browsers have visited the websites.'),

];

function PrivContainer() {
  return (
    <div className='term-cont-outer'>
        <div className='term-inner-cont'>
            
            <h2>1. Introduction</h2>
            {/* <h5>Interpretation</h5> */}
           <p>We, at <strong>RisoApp</strong> and our affiliated companies worldwide (collectively referred to as "RisoApp"), are dedicated to respecting your online privacy and recognize the importance of protecting and managing any personally identifiable information you share with us. This Privacy Policy ("Policy") describes how RisoApp collects, uses, discloses, and transfers personal information of users through our website <strong className='linkstrong'><a href='www.risoapp.com'>www.risoapp.com</a></strong>, mobile applications, and online services (collectively, the "Platform"). This policy applies to those who visit the Platform or whose information RisoApp otherwise receives in connection with our services, including contact information of individuals associated with RisoApp, such as partners (collectively referred to as "Users"). For the purposes of this Privacy Policy, "You" or "Your" shall refer to the individual accessing the Platform.</p>
            <h2>2. Types of Personal Information collected by Risoapp</h2>
            <p>"Personal Information" (PI) refers to any information that relates to an identified or identifiable natural person. This includes common identifiers such as name, identification number, location data, online identifiers, or any factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that person. At RisoApp, we collect information about you and your usage to enhance our services and offerings. The types of Personal Information we collect and the methods of collection depend on how you interact with us. Below are the categories of Personal Information we collect in various ways:</p>
             <ul>
              <li><p><strong>Registration Information:</strong> When you sign up for alerts or create an account on the Platform, we collect your name, email address, password, country, city, contact number, and the company/organization you are associated with.</p></li>
              <li><p><strong>Profile Information:</strong> If you register on the Platform, you may provide information typically included in a resume, such as name, contact details (including email address and mobile number), work experience, educational qualifications, and current or past salary details.
              </p></li>
              <li><p><strong>Service Usage Information:</strong> As a user of our services through the Platform, we collect information about how you use the services, including log information and location data.
              </p></li>
              <li><p><strong>Survey Participation:</strong> If you participate in surveys or fill out forms, we may collect your Personal Information, including your name, age, contact details, and preferences.
              </p></li>
              <li><p><strong>Demographic Information:</strong> We may collect information about your caste and eligibility for affirmative action programs or policies if you choose to provide it.
              </p></li>
              <li><p><strong>Communication Information:</strong> When you communicate with RisoApp or use the Platform to interact with other users (such as partners), we collect information about your communication and any details you decide to share.
              </p></li>
              <li><p><strong>Technical Information:</strong> When you visit our Platform, we use cookies to automatically collect, store, and use technical information about your system and interactions with the Platform and the services you access.
              </p></li>
              <li><p><strong>Affiliate Data Sharing:</strong> If you access services offered by our affiliates through the Platform, we will share your data with them in accordance with this policy. When using affiliate services, you will be governed by their terms and privacy policies, so we encourage you to review those to understand how they handle your data.
              </p></li>
              <li><p><strong>Device Information:</strong> When you access the Platform via a device, we may collect technical or related information from that device, including its location.
              </p></li>
              <li><p><strong>Communication Monitoring:</strong> To the extent permitted by law, RisoApp may monitor and record your communications with us to comply with legal and regulatory obligations and internal policies, which may include recording telephone conversations.
              </p></li>
              <li><p><strong>Social Media Integration:</strong> If you choose to sign in with your social media account or connect it to our services, you consent to our collection, storage, and use of the information made available through that interface, in accordance with this Privacy Policy. For more details, please refer to your social media provider's privacy policy.
              </p></li>
             </ul>
             <h2>3. How RisoApp May Use Your Personal Information</h2>
             <p>At RisoApp, we will only use your personal data in a fair and reasonable manner, where we have a lawful basis to do so. The usage of your personal information will depend on how you interact with us. We may process your Personal Information for the following purposes:</p>
            
             <ul>
              <li><p><strong>Service Provision:</strong> To provide our services and products to you, including sending job alerts, calendar notifications, relevant search results, recommended job opportunities, and other communication through social media.</p></li>
              <li><p><strong>User Protection and Support:</strong> To protect our users and offer customer support when needed.
              </p></li>
              <li><p><strong>User Experience Improvement:</strong> We utilize information collected from cookies and other technologies to enhance your user experience and the overall quality of our services. For tailored advertisements, we will not link identifiers from cookies or similar technologies with sensitive categories, such as race, religion, sexual orientation, or health.</p></li>
              <li><p><strong>Platform Improvement:</strong> To enhance the Platform and its content, providing better features and services.
              </p></li>
              <li><p><strong>Market Research:</strong> To conduct market research and surveys aimed at improving our products and services.
              </p></li>
              <li><p><strong>Marketing Communications:</strong> To send you information about our products and services (including those of our affiliates) for marketing purposes and promotions.</p></li>
              <li><p><strong>Crime Prevention and Investigation:</strong> To prevent, detect, investigate, and take action against crimes, including fraud and other financial crimes, as well as any illegal activities, suspected fraud, or violations of RisoApp’s Terms of Use.
              </p></li>
              <li><p><strong>Legal Compliance:</strong> To the extent required for identity verification, government sanctions screening, and due diligence checks.
              </p></li>
              <li><p><strong>Legal Rights Protection:</strong> To establish, exercise, or defend legal rights in connection with legal proceedings, including any potential legal actions, and to seek professional or legal advice related to such proceedings.
              </p></li>
              
             </ul>
            
            <h2>4. Cookies and Other Tracking Technologies</h2>
            <p>At RisoApp, we utilize "cookies" and other tracking technologies on some of our web pages. A "cookie" is a small text file that helps collect information about website activity. Some cookies and similar technologies may be used to recall Personal Information that you have previously provided. Most browsers allow you to control cookies, including whether to accept them and how to remove them.
            </p>
            <p>You can configure most browsers to notify you when you receive a cookie or to block cookies entirely. However, please be aware that if you choose to delete or block cookies, you may need to re-enter your original user ID and password to access certain sections of the Platform.</p>
             <p>Our tracking technologies may collect information such as internet domain and host names, Internet Protocol (IP) addresses, browser software and operating system types, clickstream patterns, and the dates and times our site is accessed. By using cookies and other tracking technologies, we aim to enhance our Platform and improve the overall user experience. Additionally, we may analyze information that does not contain Personal Information for trends and statistical insights.
             </p>
             <p>For more information about our use of cookies please refer to our <strong className='linkstrong'><a>Cookie Policy.</a></strong></p>
            <h2>5. The Basis/Grounds for Collection and Processing of Your Personal Information</h2>
            <p>At RisoApp, your Personal Information is collected and processed based on the following legal grounds, which depend on the nature of the Personal Information and the purposes for which it is processed:
            </p>
            <br/>
            <ul>
              <li><strong>Consent:</strong></li>
              <p>We rely on your consent to process your Personal Information in certain situations. If we require your consent to collect and process specific Personal Information, as mandated by applicable data protection laws, we will seek your consent at the time of collection. Processing will only occur after your consent has been obtained.
              </p>
              <li><strong>Compliance with Legal Obligations:
              </strong></li>
              <p>Your Personal Information may also be processed by RisoApp to the extent that such processing is necessary for us to comply with legal obligations. For example, we may need to disclose your Personal Information in response to a court order or retain specific records for a required period to meet the demands of applicable laws.
              </p>
            </ul>

            <h2>6. Information Sharing and Disclosure</h2>
            <p>At RisoApp, we limit access to your Personal Information to employees who reasonably need to know that information to fulfill their roles in providing, operating, developing, or improving our products or services.
            </p>
            <p>RisoApp does not disclose, transfer, or share your Personal Information with others except under the following circumstances:
            </p>
            <ul>
              <li><p><strong>Affiliates and Group Companies:</strong> We may share your information with our affiliates and group companies as necessary for internal business, administrative purposes, and the provision of services aimed at enhancing your career.
              </p></li>
              <li><p><strong>Potential Recruiters:</strong> If we determine that your resume aligns with a job vacancy, we may share your information with potential recruiters. By registering on the Platform and consenting to this Privacy Policy, you agree that RisoApp may contact you or forward your resume to these recruiters.
              </p></li>
              <li><p><strong>Legal and Regulatory Authorities:</strong> We may disclose your Personal Information to enforcement, regulatory, and judicial authorities if we believe it is necessary to respond to subpoenas, court orders, or legal processes; establish or exercise our legal rights; defend against legal claims; investigate or prevent illegal activities; or address potential threats to the safety of individuals.
              </p></li>
              <li><p><strong>Business Transfers:</strong> In the event of a merger, acquisition, financing, sale of assets, or any situation involving the transfer of some or all of RisoApp’s business assets, we may disclose Personal Information to parties involved in the negotiation or transfer.
              </p></li>
              <li><p><strong>Third-Party Service Providers:</strong> We may engage third-party service providers and marketing partners to provide services on our behalf, maintain the Platform and mailing lists, or communicate with you about offers related to our products and services. We will take reasonable steps to ensure these third parties are obligated to protect your Personal Information and adhere to appropriate confidentiality agreements.
              </p></li>
              <li><p><strong>Third-Party Advertisers:</strong> We may allow third-party advertisers to display advertisements when you visit the Platform. These advertisers may use information about your visits to provide targeted advertisements. RisoApp does not provide any Personal Information to the advertisers when you interact with or view a targeted advertisement. However, if you engage with an advertisement, the advertiser may make assumptions based on your interactions.
              </p></li>
              
            </ul>
            <p>RisoApp does not intend to transfer your Personal Information without your consent to third parties not acting on our behalf unless required by law.</p>
              <p>If your Personal Information is transferred outside India, we will take the necessary steps to protect your Personal Information in accordance with applicable data protection laws.
              </p>
            <p>Risoapp’s use and transfer to any other app of information received from Google APIs will adhere to <strong className='linkstrong'>Google API Services</strong> User Data Policy, including the Limited Use requirements.
            </p>
           
            <h2>7. Third-Party Content</h2>
            <p>Please be aware that the RisoApp Platform may contain links to other sites that are not governed by this Privacy Policy. Users may be directed to third-party sites for more information, including advertisers, blogs, content sponsorships, vendor services, social networks, and more.
            </p>
            <p>We partner with affiliates to provide additional services related to the Platform, such as career enhancement, skill development, and interview preparation. For instance, we offer <strong>RisoHire</strong>—a service that provides resources and support to help you with your career and skill enhancement. If you access RisoHire through the RisoApp platform, your personal data will be governed by the privacy policy of RisoHire.
            </p>
            <p>You may review the privacy policy of RisoHire services <strong className='linkstrong'><Link to="/services">here.</Link></strong></p>
             <p>To the extent that your data is retained by RisoApp, this Policy continues to apply. However, RisoApp makes no representations or warranties regarding how your information is stored or used on third-party servers. We recommend that you review the applicable privacy statements and policies of each third-party site linked from the Platform to understand their use of your personal information.</p>
             <h2>8. Children</h2>
             <p>To use the RisoApp Platform, you agree that you must be at least the minimum age described in this paragraph or older.
             </p>
             <p>The minimum age for these purposes shall be eighteen (18). However, if local laws require that you must be older for RisoApp to lawfully provide the services over the Platform to you, then that older age shall apply as the applicable minimum age.
             </p>
             <p>If you are under the age of 18 or the age of majority in your jurisdiction, you must use the Platform under the supervision of a parent, legal guardian, or responsible adult.
             </p>
             <h2>9. Retention of Personal Information
             </h2>
             <p>Your Personal Information will not be retained by RisoApp any longer than necessary for the purposes for which it is processed and/or in accordance with legal, regulatory, contractual, or statutory obligations as applicable.
             </p>
             <p>At the expiry of such periods, your Personal Information will be deleted or archived in compliance with applicable laws.
             </p>
             <h2>10. Controlling Your Personal Information</h2>
             <p>You have the right to invoke your rights as a data principal or data subject (as per applicable laws and regulations) regarding your Personal Information processed by RisoApp.
             </p>
             <p>RisoApp provides you with the ability to keep your Personal Information accurate and up-to-date. If at any time you would like to: a) rectify, update, or correct your Personal Information; b) obtain confirmation on whether or not your Personal Information is being processed; c) access your Personal Information or exercise your right to data portability; d) exercise your right to restrict the continuing disclosure of your Personal Information to any third party by RisoApp in certain circumstances,
             </p>
             <p>you are requested to contact us using the contact details mentioned in paragraph 14 below. We will require you to provide valid proof of your identity to ensure that your rights are respected.
             </p>
             <p>For the exercise of certain rights, you may be required to approach the relevant authority or designated officer per the provisions of the applicable data protection laws. RisoApp may, in accordance with applicable data protection laws, charge a fee for fulfilling your request, particularly in cases of excessive or manifestly unfounded requests. Furthermore, you acknowledge that the rights mentioned above are not absolute and are subject to limitations as per applicable data protection laws.
             </p>
             <p>If you wish to delete your account on RisoApp, you may do so by using the settings available in your account.
             </p>
             <h2>11. Confidentiality and Security</h2>
             <p>The security and confidentiality of your Personal Information is important to us at RisoApp. We have invested significant resources to protect the safekeeping and confidentiality of your personal data. When using external service providers acting as processors, we require that they adhere to the same standards as RisoApp does. Regardless of where your Personal Information is transferred or stored, we take all reasonable steps necessary to ensure that your data is kept secure.
             </p>
             <p>We seek to ensure compliance with the requirements of applicable data protection laws to protect and preserve your privacy. We have implemented physical, electronic, and procedural safeguards that comply with the laws in India to protect your Personal Information. By accepting the terms of this Privacy Policy, you agree that the standards and practices being implemented by us are reasonable and sufficient for the protection of your Personal Information.
             </p>
             <h2>12. Social Media</h2>
             <p>RisoApp operates channels, pages, and accounts on various social media sites to inform, assist, and engage with our users. We monitor and record comments and posts made on these channels to improve our products and services.
             </p>
             <p>Please note that you must not communicate the following information to RisoApp through such social media sites:
             </p>
             <ul>
              <li><p><strong>Sensitive Personal Data:</strong> This includes any information revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, as well as genetic data, biometric data for uniquely identifying an individual, data concerning health, or data concerning an individual's sex life or sexual orientation. Additionally, it includes other sensitive personal data such as criminal convictions, offenses, and national identification numbers.
              </p></li>
              <li>
                <p>
                <strong>Excessive, Inappropriate, Offensive, or Defamatory Content:</strong> RisoApp is not responsible for any information posted on these sites other than the information posted by our employees on our behalf. We are only responsible for our own use of the Personal Information received through such sites.
                </p>
              </li>
             </ul>
             <h2>13. Changes to this Privacy Policy</h2>
             <p>RisoApp reserves the right to update, change, or modify this Privacy Policy at any time. Any updates, changes, or modifications will take effect from the date of publication. We encourage users to review this Privacy Policy periodically to stay informed about how we are protecting your information.
             </p>
             <h2>14. Disclaimer</h2>
             <p>RisoApp does not store any account-related information or any credit/debit card details. We shall not be liable for any loss or damage sustained by users as a result of any disclosure (inadvertent or otherwise) of information concerning the user’s account, credit cards, or debit cards during online transactions or payments made for any products and/or services offered through the Platform.
             </p>
             <p>If you share any Personal Information with RisoApp that was not requested during registration (whether mandatorily or optionally), we will not be liable for any information security breach or disclosure concerning such information.
             </p>
             <p>If you have any questions regarding this Privacy Policy or the protection of your Personal Information, please contact RisoApp’s Data Protection Officer/Grievance Officer at the following: [Contact Information].
             </p>
             <h2>15. Data Protection Officer/Grievance Officer</h2>
             <p>In case you have any complaints and/or grievances in relation to the processing of your Personal Information, you can send your complaints via e-mail to our Grievance Officer:
             </p>
              <br/>
             <div>
                <div>Grievance Officer</div>
                <div>RisoApp</div>
                <div>Email: <strong className='linkstrong'>privacy</strong>@risoapp.com</div>
             </div>
             <br/>
             <h2>Cookie Policy</h2>
             <h5>Introduction</h5>
             <h5>Cookie Policy</h5>
             <p>This Cookie Policy ("Policy") explains how RisoApp uses cookies and similar tracking technologies on our platform. We are committed to transparency and believe in being open about how we use your information. This Policy provides detailed information about how and when we use cookies on our websites and services.</p>
             <p>This Cookie Policy applies to any RisoApp product or service that links to this policy or incorporates it by reference.
             </p>
             <h5>a. Does RisoApp use cookies?</h5>

             <p>RisoApp uses cookies, tags, and other tracking technologies when you access any of our websites, mobile sites, or mobile apps, as well as any third-party services used through the RisoApp platform (collectively referred to as "the services"). Cookies help us ensure that you have the best possible experience while using our services, and they also contribute to keeping your account secure. By continuing to visit or use our services, you agree to the use of cookies and similar technologies for the purposes outlined in this policy. If you prefer not to receive cookies or tracking technologies, you should stop using our site or adjust your browser and third-party cookie settings as described below.
             </p>
             <h5>b. What is a cookie?</h5>
             <p>Cookies are small text files stored by a website you visit in your browser, which are then sent back by your web browser in every request to that website. A cookie file is saved in your web browser and allows RisoApp or a third party to recognize you, making your subsequent visits easier and our services more useful to you. Essentially, cookies serve as an identification card for RisoApp's servers. They enable RisoApp to serve you better and more efficiently, personalizing your experience on our platform. Additionally, web beacons, tags, and scripts may be utilized on our websites or in emails to help deliver cookies, count visits, understand usage and campaign effectiveness, and determine whether an email has been opened and acted upon. We may analyze the usage of these technologies and generate reports on both an individual and aggregated basis.</p>
             <p>Cookies can be classified as "persistent" or "session" cookies. A persistent cookie helps us recognize you as an existing user, making it easier for you to return to RisoApp or engage with our services without needing to sign in again. Once you sign in, a persistent cookie remains in your browser and is read by RisoApp when you return to one of our sites or a partner site utilizing our services. In contrast, session cookies last only for the duration of your session (typically the current visit to a website or a browser session).</p>
             <h5>c. What are cookies used for?</h5>
             <p>When you visit RisoApp, we may place cookies in your browser, known as First Party Cookies. These cookies help maintain session information as you navigate our site. They enable us to understand user preferences, enhance the overall experience, and track usage and statistical information. Additionally, cookies allow us to deliver personalized advertising and features. You can manage cookie settings through your browser; however, disabling cookies may limit your ability to access certain features of our website.</p>
             {/* table */}

             <TableContainer component={Paper}>
      <Table sx={{ minWidth: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell align="left">Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

             {/* table end */}
             <h5>d. When does RisoApp place cookies?</h5>
             <p>We use cookies on the RisoApp website and mobile applications. Any browser accessing our platforms will receive cookies, allowing us to recognize you more quickly on your return visits. Cookies help us understand which pages and information you find most useful or interesting.</p>
            <p>RisoApp may also utilize its own analytics cookies, separate from Google Analytics, to generate statistics and reports about visitor activities on our site and app.
            </p>
            <h5>e. How are cookies used for online analytics purposes?</h5>
            <p>We may use web analytics services on RisoApp, such as Google Analytics, to analyze how users interact with our services. These services track data, including the third-party websites from which you arrive. The information collected by these technologies is disclosed to or collected directly by the service providers, who use it to evaluate your usage of our services. Additionally, we utilize Google Analytics for various online marketing purposes, as described in the following sections.
            </p>
            <h5>f. How are cookies used for advertising purposes?</h5>
            <p>Cookies and other advertising technologies, such as beacons, pixels, and tags, help us serve relevant ads to you more effectively. They enable us to provide aggregated auditing, research, and reporting for advertisers, improve our services, and track when content has been displayed to you. We collaborate with website analytics and advertising partners, including Google Display Network and Facebook, to deliver RisoApp advertisements on third-party publisher websites. These partners may set cookies on your computer's web browser, allowing them to recognize your device and display RisoApp ads across the Internet. Additionally, our analytics software measures your engagement and interactions while using RisoApp services. This process allows ad networks to compile anonymous, de-identified information about where you, or others using your device, encountered our advertisements, whether you interacted with them, and subsequent actions taken on RisoApp websites and applications. This information helps ad networks deliver targeted advertisements that align with your interests, and it enables RisoApp to optimize our advertising campaigns and website usability. In essence, we use analytics data alongside data about our online ads shown through the Google Display Network, Facebook for Advertisers, or other advertising networks to understand how anonymous users interacted with our website after viewing our ads.
            </p>
            <h5>g. What third-party cookies does RisoApp use?</h5>
            <p>Please note that third parties, including advertising networks and providers of external services like web traffic analysis, may also use cookies on our services. The names of cookies, pixels, and other technologies may change over time. We partner with trusted companies to assist with advertising, which may place cookies on your device. Additionally, we may integrate content from social networks into our pages, such as embedded feeds from platforms like Facebook. These social networks may also set cookies on your device. For example, if you log into Facebook, Twitter, or Google via our website, a cookie will be placed on your computer, similar to logging into these social networks directly.
            </p>
            <p>We utilize Google Analytics on our services to help us analyze user interactions. Google Analytics employs performance cookies to track customer interactions, providing insights into which pages are viewed, popular content, peak visiting times, whether visitors are returning users, referral sources, and other related information. All data collected through these cookies is anonymized.
            </p>
            <p>
            We recommend reviewing the respective privacy policies of these external services to understand what data they collect about you and how they process it.

            </p>
           <p><strong>Facebook:</strong> <strong className='linkstrong'><a href='https://www.facebook.com/policy.php'>https://www.facebook.com/policy.php</a></strong></p>
           <p><strong>AdSense:</strong> <strong className='linkstrong'><a href='https://policies.google.com/technologies/ads'>https://policies.google.com/technologies/ads</a></strong></p>
           <p><strong>Google Analytics:</strong> <strong className='linkstrong'><a href='https://www.google.com/analytics/learn/privacy.html'>https://www.google.com/analytics/learn/privacy.html</a></strong></p>
           <p><strong>Google Tag Manager:</strong> <strong className='linkstrong'><a href='https://www.google.com/analytics/tag-manager/faq/'>https://www.google.com/analytics/tag-manager/faq/</a></strong></p>
           <p><strong>Google+:</strong> <strong className='linkstrong'><a href='https://www.google.com/policies/privacy/'>https://www.google.com/policies/privacy/</a></strong></p>
           <p><strong>Twitter:</strong> <strong className='linkstrong'><a href='https://twitter.com/en/privacy'>https://twitter.com/en/privacy</a></strong></p>
           <p><strong>Google Double click:</strong> <strong className='linkstrong'><a href='https://support.google.com/dfp_premium/answer/2839090?hl=en'>https://support.google.com/dfp_premium/answer/2839090?hl=en</a></strong></p>
        
           <h5>h. What are Web Beacons?</h5>
           <p>RisoApp occasionally advertises on third-party websites. To track the success of our advertising campaigns, we may use visitor identification technologies such as "web beacons" or "action tags," which count visitors who arrive at our site after viewing a RisoApp advertisement on a third-party site. Our partners may also use web beacons to collect aggregated data provided by your browser while you are on our site or another site where RisoApp is featured. </p>
           <p>
           By navigating on our site, you agree to the placement of cookies and web beacons on your computer or device. If you prefer not to receive web beacons, you should either stop using our site or adjust your browsing settings accordingly.
           </p>
        <h5>i. How to Control Cookies?</h5>
        <p>Most browsers allow you to manage cookies through their settings preferences. If you choose to disable cookies, you will still see advertising on the internet, but it may not be tailored to your interests. Disabling cookies does not mean you won't receive any advertisements while online. While we have mentioned most of the third parties involved in using targeting or advertising cookies in previous sections, this list is not exhaustive and may change. Therefore, even if you disable all the third-party cookies listed, you may still receive some tailored advertisements and recommendations.
        </p>
        <p>
        There are various ways to manage the cookies set on your devices. However, essential cookies cannot be disabled. Not allowing certain cookies may limit your access to our website and affect its performance, features, or services.
        </p>
        <p>You can also manage cookie settings in the privacy options of your web browser. Please be aware that if you block all cookies through your browser settings, you may not be able to access certain parts of our website or others'.</p>
        <p><strong><em>Changing your Cookie Settings:</em></strong> The browser settings for changing your cookies settings are usually found in the 'options' or 'preferences' menu of your internet browser. In order to understand these settings, the following links may be helpful. Otherwise you should use the 'Help' option in your internet browser for more details.        </p>
         
         <ul>
          <li><p><strong className='linkstrong'><a href='https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies'>Cookie settings in Internet Explorer</a></strong></p></li>
          <li><p><strong className='linkstrong'><a href='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'>Cookie settings in Firefox</a></strong></p></li>
          <li><p><strong className='linkstrong'><a href='https://support.google.com/chrome/answer/95647'>Cookie settings in Chrome</a></strong></p></li>
          <li><p><strong className='linkstrong'><a href='https://support.apple.com/en-in/guide/safari/sfri11471/mac'>Cookie settings in Safari</a></strong></p></li>
         </ul>

         <p><strong><em>More information:</em></strong> To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit <strong className='linkstrong'><a href='https://www.allaboutcookies.org/'>www.allaboutcookies.org</a></strong>. You can learn more about opting out of receiving interest-based ads from other companies at <strong className='linkstrong'><a href='https://optout.aboutads.info/'>optout.aboutads.info</a></strong> and <strong className='linkstrong'><a href='https://www.networkadvertising.org/choices'>www.networkadvertising.org/choices</a></strong>. In addition, certain third party advertising networks, like Facebook (pixels) and Google, permit users to opt out of or customize preferences associated with your internet browsing. To learn more about this feature from Google, click here. For further information about the Facebook Pixel, click <strong className='linkstrong'><a href='https://www.facebook.com/business/help/651294705016616'>here</a></strong>.
         </p>
        
        </div>
    </div>
  )
}

export default PrivContainer