import React from "react";
import Navbar from "../pagesections/Navbar";
import Herocontenta from "./Herocontenta";

function Heroabout() {
  return (
    <div className="hero-sec-container">
      <Navbar />
      <Herocontenta />
    </div>
  );
}

export default Heroabout;
