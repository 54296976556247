import React, { useState } from "react";
import "./TopCompanies.css";


// import topCompaniesObjectList from "../../objects/TopCompanies";
import hire_arrow from "../../assets/images/hire_arrow.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTrendingDetQuery } from "../../slice/authApiSlice";
import { generatePublicUrl } from "../../config";
import Comming from "../Commingsoon/Comming";
// import MobileTopCompanies from "./mtopCompanies/MobileTopCompanies";
// import { useTrendingDetQuery } from "../../slice/authApiSlice";
// import { generatePublicUrl } from "../../config/image";

const TopCompanies = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { data:trendingDet, isLoading: isLoadingTrending, error: trendingError } = useTrendingDetQuery();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  console.log(trendingDet);
  
  
  
  return (
    <div className="topcomp_section">
      <div className="topcomp_container">
        <section className="top_inr">
        <div className="heading">
          <h1 className="section_heading">Trending job roles in India</h1>
        </div>
        <div className="topcom_card_container">
          <Slider {...settings} className="topcomp_slider">
            {trendingDet?.data?.map((company, index) => {
              return (
                <div className="topcomp_card">
                      <section className="trending_inner_card">
                          <div className="trend_logo">
                            <img src={generatePublicUrl(company?.companyInfo[0]?.companyLogo)}/>
                          </div>
                          <div className="trend_Head">
                            <h2>{company.title}</h2>
                            <p>{company?.experienceStart} - {company?.experienceEnd} Years of exprience | openings: {company.numPosition}</p>
                          </div>
                          <div className="trend_det">
                              <h4>{company.companyInfo[0]?.companyName}</h4>
                              <p>{company.companyInfo[0].city}, {company.companyInfo[0].state}, {company.companyInfo[0].country}</p>
                              <p>({company.jobType}, {company?.location})</p>
                          </div>
                          
                          <div className="tren_button">
                            <hr/>
                            <button>Match with my profile</button>
                          </div>
                      </section>
                </div>
              );
            })}
          </Slider>
        </div>

      
        </section>
        <div className="banner_trend">
        <div className="banner_inr">
            <h2>Post your job for millions of people to see and find the right candidate for your needs.</h2>
            <button onClick={handleOpen}>Post job</button>
        </div>
</div>
      </div>
      <div className="mtc_section">
        {/* <MobileTopCompanies /> */}
      </div>
      <Comming open={open} handleClose={handleClose} />
    </div>
  );
};

export default TopCompanies;
