import React from "react";
import "./Herosec.css";
import Navbar from "./Navbar";
import Herocontentl from "./Herocontentl";
import { useLocation } from "react-router-dom";
import HerocontentS from "./HerocontentS";
import HerocontentC from "./HerocontentC";
import HerocontentE from "./HerocontentE";
import HerocontentB from "./HerocontentB";

function Herosec() {
  const location = useLocation();
  return (
    <>
      <Navbar />
    <div className="hero-sec-container">
    <Navbar />
      {location.pathname === "/services" ? (
  <HerocontentS />
) : location.pathname === "/councellors" ? (
  <HerocontentE />
) : location.pathname === "/blogs" ? (
  <HerocontentB />
): (
  <Herocontentl />
)}

    </div>
    </>
  );
}

export default Herosec;
